import { FC } from "react";

import { MeasurementSystem } from "src/js/stores/user/store";
import { SupportedLocale } from "src/js/util/dates";
import { ComparablesUKDataAttribution } from "react-migration/layouts/map/Multilayer/ComparablesUKDataAttribution";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { RecordType } from "react-migration/domains/comparables/typings/Record";
import { AdditionalLandTechTransactions } from "../../AdditionalTransactions/AdditionalLandTechTransactions";
import { bedroomCountTitle, bedroomCountTooltip, bedroomCountValue } from "../helpers/bedrooms";
import { floorAreaTitle, floorAreaTooltip, floorAreaValue } from "../helpers/floorArea";
import { propertyTypeTitle, propertyTypeValue } from "../helpers/propertyType";
import { ppuaTitle, ppuaTooltip, ppuaValue } from "../helpers/ppua";
import { newBuildTitle, newBuildValue } from "../helpers/newBuild";
import { marketTitle, marketTooltip, marketValue } from "../helpers/marketValue";
import { tenureTitle, tenureValue } from "../helpers/tenure";
import { saleTitle, saleValue } from "../helpers/saleValue";
import { RecordItemUnavailable } from "../RecordItemUnavailable";
import { RecordItem } from "../RecordItem";

export interface LandTechSectionProps {
  record: RecordType;
  locale: SupportedLocale;
  unitPreference: MeasurementSystem;
}
export const LandTechSection: FC<LandTechSectionProps> = ({ record, locale, unitPreference }) => {
  const { t } = useTranslation();

  if (!record.price) {
    return <RecordItemUnavailable source="epc" />;
  }

  return (
    <>
      <RecordItem title={saleTitle({ t })} value={saleValue({ t, record, locale })} />
      <AdditionalLandTechTransactions transaction={record} />
      <RecordItem
        title={marketTitle({ t })}
        value={marketValue({
          record,
          locale,
        })}
        tooltip={marketTooltip({ t, record })}
      />
      <RecordItem
        title={ppuaTitle({ t, unitPreference })}
        value={ppuaValue({ t, record, locale, unitPreference })}
        tooltip={ppuaTooltip({ t })}
      />
      <RecordItem title={propertyTypeTitle({ t })} value={propertyTypeValue({ t, record })} />
      <RecordItem title={newBuildTitle({ t })} value={newBuildValue({ t, record })} />
      <RecordItem
        title={bedroomCountTitle({ t })}
        value={bedroomCountValue({ t, record })}
        tooltip={bedroomCountTooltip({ t, record })}
      />
      <RecordItem title={tenureTitle({ t })} value={tenureValue({ t, record })} />
      <RecordItem
        title={floorAreaTitle({ t })}
        value={floorAreaValue({ t, record, unitPreference })}
        tooltip={floorAreaTooltip({ t, record })}
      />
      <ComparablesUKDataAttribution />
    </>
  );
};
