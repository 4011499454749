import { Accessor, Color, MVTLayer, MVTLayerProps } from "deck.gl";
import T from "ramda/src/T";
import { DataFilterExtension, MaskExtension } from "@deck.gl/extensions";
import {
  HOVER_HIGHLIGHT_COLOR_BY_MAP_TYPE,
  PROPRIETOR_COLOR_MAP,
  SATELLITE_LINE_COLOR,
  STREETMAP_LINE_COLOR,
  TRANSPARENT,
} from "../../util/Colors";
import { Feature, GeoJsonProperties, Geometry, MultiPolygon, Polygon } from "geojson";
import type { FeatureFilter } from "./OwnershipLayerComponent";
import { ENVIRONMENT } from "src/js/util/environment";
import type { BaseMapType } from "src/js/stores/map/store";
import { OwnershipFeature, OwnershipFeatureProperties } from "../../typings/Feature";
import { POINTLESSLY_LARGE_NUMBER } from "src/js/util/units_and_constants";

export const OWNERSHIP_LAYERS = {
  OWNERSHIP: "ownership",
};
const getFiltersValues = (ownershipFeature: OwnershipFeature, featureFilter: FeatureFilter) => {
  return [featureFilter(ownershipFeature) ? 1 : 0, ownershipFeature.properties.area];
};
const DEFAULT_MIN_ZOOM = 12;
const DEFAULT_MAX_ZOOM = 19;
const OWNERSHIP_VEC_TILE_API_URL_TEMPLATE = `${ENVIRONMENT.OWNERSHIP_SERVICE_URL}/tiles/{z}/{x}/{y}`;
type OwnershipLayerExtraProps = {
  data?: MVTLayerProps["data"];
  displayKnownOwners?: boolean;
  featureFilter?: FeatureFilter;
  fillOpacityMultiplier?: number;
  geofenceGeometries?: Feature<Polygon | MultiPolygon, GeoJsonProperties>[];
  mapType?: BaseMapType;
  maskId?: string;
  parcelSizeMin?: number;
  parcelSizeMax?: number;
  showBoundaries?: boolean;
};

export type OwnershipLayerProps = Omit<MVTLayerProps<OwnershipFeatureProperties>, "data"> &
  OwnershipLayerExtraProps;
const defaultOwnershipLayerProps: Partial<MVTLayerProps<OwnershipFeatureProperties>> &
  OwnershipLayerExtraProps = {
  id: "ownership-layer",
  data: OWNERSHIP_VEC_TILE_API_URL_TEMPLATE,
  pickable: true,
  uniqueIdProperty: "title_no",
  autoHighlight: true,
  highlightColor: HOVER_HIGHLIGHT_COLOR_BY_MAP_TYPE["satellite"] as number[],
  fillOpacityMultiplier: 1,
  stroked: true,
  filled: true,
  binary: true,
  minZoom: DEFAULT_MIN_ZOOM,
  maxZoom: DEFAULT_MAX_ZOOM,
  parcelSizeMin: 0,
  parcelSizeMax: POINTLESSLY_LARGE_NUMBER,
  showBoundaries: false,
  maskId: "Geofence",
  geofenceGeometries: [],
  visible: true,
  featureFilter: T,
  mapType: "satellite" as BaseMapType,
  loadOptions: {
    mvt: {
      layers: ["ownership"],
    },
  },
  lineWidthUnits: "pixels",
  _subLayerProps: {
    "polygons-stroke": {
      pickable: false,
    },
  },
};

export class OwnershipLayer extends MVTLayer<OwnershipFeatureProperties> {
  constructor(
    {
      autoHighlight,
      displayKnownOwners,
      fillOpacityMultiplier,
      featureFilter,
      geofenceGeometries,
      getLineWidth,
      highlightColor,
      mapType,
      maxZoom,
      minZoom,
      parcelSizeMin,
      parcelSizeMax,
      showBoundaries,
      visible,
      ...props
    }: OwnershipLayerProps,
    ...otherLayers: unknown[]
  ) {
    const maskExtensions = [] as MaskExtension[];

    // using default value in the destructure above will not work how we want. We want to use the default value if the value is undefined
    // but if the key exists as undefined it will not use the fallback so we have to set them below using the nullish coalescing operator
    autoHighlight ??= defaultOwnershipLayerProps.autoHighlight!;
    displayKnownOwners ??= defaultOwnershipLayerProps.displayKnownOwners!;
    getLineWidth ??= showBoundaries ? 1 : 0;
    featureFilter ??= defaultOwnershipLayerProps.featureFilter!;
    fillOpacityMultiplier ??= defaultOwnershipLayerProps.fillOpacityMultiplier!;
    highlightColor ??= defaultOwnershipLayerProps.highlightColor!;
    mapType ??= defaultOwnershipLayerProps.mapType!;
    maxZoom ??= defaultOwnershipLayerProps.maxZoom!;
    minZoom ??= defaultOwnershipLayerProps.minZoom!;
    parcelSizeMin ??= defaultOwnershipLayerProps.parcelSizeMin!;
    parcelSizeMax ??= defaultOwnershipLayerProps.parcelSizeMax!;
    visible ??= defaultOwnershipLayerProps.visible!;

    if (geofenceGeometries?.length) {
      maskExtensions.push(new MaskExtension());
    }

    super(
      {
        ...defaultOwnershipLayerProps,
        getLineWidth,
        autoHighlight,
        highlightColor,
        getLineColor:
          mapType === "satellite" || mapType === "hybrid"
            ? SATELLITE_LINE_COLOR
            : STREETMAP_LINE_COLOR,
        getFillColor: (!displayKnownOwners
          ? TRANSPARENT
          : (d) => {
              // d.properties.proprietorship is always a string for UK properties
              const [r, g, b, a] =
                PROPRIETOR_COLOR_MAP[d.properties.proprietorship!] ?? TRANSPARENT;

              return [r, g, b, a * fillOpacityMultiplier!] as Color;
            }) as Accessor<Feature<Geometry, OwnershipFeatureProperties>, Color>,
        updateTriggers: {
          getFillColor: [displayKnownOwners, fillOpacityMultiplier],
          getFilterValue: [featureFilter],
        },
        extensions: [new DataFilterExtension({ filterSize: 2 }), ...maskExtensions],
        // @ts-expect-error DataFilterExtension uses this props
        getFilterValue: (d: OwnershipFeature) => getFiltersValues(d, featureFilter),
        filterRange: [
          [1, 1],
          [parcelSizeMin, parcelSizeMax === Infinity ? POINTLESSLY_LARGE_NUMBER : parcelSizeMax],
        ],
        maskId: geofenceGeometries?.length ? defaultOwnershipLayerProps.maskId : undefined,
        maxZoom,
        minZoom,
        visible,
        ...props,
      },
      ...otherLayers
    );
  }
}
