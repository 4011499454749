import { PropsWithChildren, createContext, useCallback, useContext } from "react";
import { logEventV2 } from "react-migration/lib/util/logEventV2";
import { useDebounceCallback } from "react-migration/lib/util/useDebounceCallback";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TrackControlChange = (values: { filterName: string; filterState: any }) => void;

interface ControlTrackingContextValue {
  layerId: string;
}

const ControlTrackingContext = createContext<ControlTrackingContextValue | null>(null);

export const useControlTracking = () => {
  const contextValue = useContext(ControlTrackingContext);

  const trackControlChange = useCallback<TrackControlChange>(
    ({ filterName, filterState }) => {
      if (contextValue?.layerId) {
        logEventV2({
          name: "Layers - Individual layer filter click",
          properties: {
            filterName,
            filterState,
            layerId: contextValue.layerId,
          },
        });
      }
    },
    [contextValue?.layerId]
  );

  const debouncedTrackControlChange = useDebounceCallback(trackControlChange, 500);

  return { debouncedTrackControlChange, trackControlChange };
};

interface ControlTrackingProviderProps {
  layerId: string;
}

export function ControlTrackingProvider({
  layerId,
  children,
}: PropsWithChildren<ControlTrackingProviderProps>) {
  return (
    <ControlTrackingContext.Provider value={{ layerId }}>
      {children}
    </ControlTrackingContext.Provider>
  );
}
