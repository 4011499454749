import { _WMSLayer as WMSLayer } from "@deck.gl/geo-layers";
import { buildConstraintsTileUrl } from "react-migration/domains/constraints/components/ConstraintLayer/ConstraintLayer";
import { DesignationLayerLight } from "react-migration/domains/constraints/components/ConstraintLayer/DesignationLayer";
import { useMapLayer } from "react-migration/lib/map/useMapLayer";
import { LayerTypeMapLayerProps } from "../../types";
import { SimplifyProfile } from "../ConstraintsLayerType";
import { useSelectedTopographyLayer } from "./useSelectedTopographyLayer";
import { TopographyLayer } from "./constants";

export const TopographyMapLayer = ({ visible, zOrder }: LayerTypeMapLayerProps) => {
  const [selectedLayer] = useSelectedTopographyLayer();

  if (!visible) {
    return null;
  }

  if (selectedLayer === TopographyLayer.hillshade) {
    return <HillshadeTopographyLayer zOrder={zOrder} />;
  }

  if (selectedLayer === TopographyLayer.contour) {
    return <ContourTopographyLayer zOrder={zOrder} />;
  }

  if (selectedLayer === TopographyLayer.hillshadeContour) {
    return (
      <>
        <HillshadeTopographyLayer zOrder={zOrder} />
        <ContourTopographyLayer zOrder={zOrder} />;
      </>
    );
  }

  return null;
};

export function renderHillshadeLayer() {
  return new WMSLayer({
    id: `topology-hillshade`,
    data: "https://environment.data.gov.uk/spatialdata/lidar-composite-digital-terrain-model-dtm-1m/wms?request=GetCapabilities&service=WMS&version=1.3.0",
    serviceType: "wms",
    layers: ["Lidar_Composite_Hillshade_DTM_1m"],
    opacity: 0.3,
    minZoom: 6,
    maxZoom: 24,
  });
}

function HillshadeTopographyLayer({ zOrder }: Pick<LayerTypeMapLayerProps, "zOrder">) {
  useMapLayer(() => ({ zOrder, layer: renderHillshadeLayer() }), [zOrder]);
  return null;
}

export function renderContourLayer() {
  return new DesignationLayerLight({
    id: "topology-contour",
    minZoom: 11,
    data: buildConstraintsTileUrl({
      rootCategory: "uk_terrain",
      simplifyProfile: SimplifyProfile.LOW,
    }),
    featureIsVisible: () => true,
  });
}

function ContourTopographyLayer({ zOrder }: Pick<LayerTypeMapLayerProps, "zOrder">) {
  useMapLayer(() => ({ zOrder, layer: renderContourLayer() }), [zOrder]);
  return null;
}
