type CouncilInstructionType = {
  name: string;
  refName: string;
  link: string;
  prePaste: string | null;
};

type UnsupportedCouncilsType = {
  "Amber Valley": CouncilInstructionType;
  Broxbourne: CouncilInstructionType;
  "East Staffordshire": CouncilInstructionType;
  "Epping Forest": CouncilInstructionType;
  Erewash: CouncilInstructionType;
  Hackney: CouncilInstructionType;
  Haringey: CouncilInstructionType;
  "South Tyneside": CouncilInstructionType;
  Tamworth: CouncilInstructionType;
  Tandridge: CouncilInstructionType;
};

export const UnsupportedCouncils: UnsupportedCouncilsType = {
  "Amber Valley": {
    name: "Amber Valley Council",
    refName: "Ref number",
    link: "https://www.ambervalley.gov.uk/planning/development-management/view-a-planning-application/",
    prePaste: "Click through to 'Search by reference number'",
  },
  Broxbourne: {
    name: "Broxbourne Council",
    refName: "Application number",
    link: "https://planning.broxbourne.gov.uk/Planning/lg/plansearch.page?org.apache.shale.dialog.DIALOG_NAME=gfplanningsearch&Param=lg.Planning",
    prePaste: null,
  },
  "East Staffordshire": {
    name: "East Staffordshire Council",
    refName: "Application Number",
    link: "http://www.eaststaffsbc.gov.uk/Northgate/PlanningExplorer/GeneralSearch.aspx",
    prePaste: null,
  },
  "Epping Forest": {
    name: "Epping Forest",
    refName: "Application Reference",
    link: "https://www.eppingforestdc.gov.uk/planning-and-building/search-planning-applications/",
    prePaste: "Select 'Search planning applications'",
  },
  Erewash: {
    name: "Erewash Council",
    refName: "Application Number ERE",
    link: "https://register.civicacx.co.uk/erewash/planning",
    prePaste: null,
  },
  Hackney: {
    name: "Hackney Council",
    refName: "Application Reference Number",
    link: "https://planningapps.hackney.gov.uk/planning/index.html?fa=search",
    prePaste: "Click through to 'Planning', then select 'Search Planning Applications'",
  },
  Haringey: {
    name: "Haringey Council",
    refName: "Application Reference",
    link: "https://publicregister.haringey.gov.uk/pr/s/register-view?c__r=Arcus_BE_Public_Register",
    prePaste: null,
  },
  "South Tyneside": {
    name: "South Tyneside Council",
    refName: "Application Number",
    link: "http://planning.southtyneside.info/Northgate/PlanningExplorer/ApplicationSearch.aspx",
    prePaste: null,
  },
  Tamworth: {
    name: "Tamworth Council",
    refName: "Application Number",
    link: "https://planning.tamworth.gov.uk/northgate/planningexplorer/generalsearch.aspx",
    prePaste: null,
  },
  Tandridge: {
    name: "Tandridge",
    refName: "Planning application reference",
    link: "https://tdcplanningsearch.tandridge.gov.uk/",
    prePaste: "Select 'Application reference' in the search criteria",
  },
};
