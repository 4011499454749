import clsx from "clsx";

import { Checkbox, twAtlasMerge } from "@landtechnologies/components";

import type { RecursiveTree } from "./types";
import { CheckboxItem } from "./TreeBuilders/checkboxTreeRecursive";
import { CollapsibleItem } from "./TreeBuilders/collapsibleTreeRecursive";
import { LabelItem } from "./TreeBuilders/labelTreeRecursive";

interface CheckboxTreeListProps<T> {
  disabled?: boolean;
  columns?: boolean;
  trees: RecursiveTree<T>[];
}

export function CheckboxTreeList<T extends CheckboxItem & CollapsibleItem & LabelItem>({
  disabled,
  columns,
  trees,
}: Readonly<CheckboxTreeListProps<T>>) {
  return (
    <ul
      className={clsx("atlas-list-none", {
        "atlas-flex atlas-flex-col atlas-gap-1": !columns,
        "atlas-columns-2 atlas-gap-6": columns,
      })}
    >
      {trees.map((tree) => (
        <li key={tree.id} className="atlas-list-none">
          <CheckboxTreeNode disabled={disabled} tree={tree} />
        </li>
      ))}
    </ul>
  );
}

interface CheckboxTreeNodeProps<T> {
  disabled?: boolean;
  tree: RecursiveTree<T>;
}

export function CheckboxTreeNode<T extends CheckboxItem & CollapsibleItem & LabelItem>({
  disabled,
  tree,
}: Readonly<CheckboxTreeNodeProps<T>>) {
  let label = tree.label;

  if (tree.isCollapsible) {
    label = (
      <button
        disabled={disabled}
        className="atlas-flex atlas-gap-1 atlas-items-center"
        onClick={() => tree.setCollapsed(!tree.collapsed)}
      >
        <i
          className={twAtlasMerge(
            "atlas-text-base",
            "atlas-w-5",
            "atlas-h-5",
            tree.collapsed ? "icon-lt-arrow-right-s-line" : "icon-lt-arrow-down-s-line"
          )}
        />

        {label}
      </button>
    );
  }

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-1">
      <label
        className="atlas-flex atlas-justify-between atlas-items-center atlas-select-none atlas-text-sm atlas-font-medium"
        data-testid="checkbox-tree-node-label"
      >
        {label}
        <Checkbox
          disabled={disabled}
          value={tree.checkboxState}
          onChange={tree.handleCheckboxChange}
        />
      </label>
      {!!tree.childNodes.length && (
        <div
          className={twAtlasMerge("atlas-ml-6", {
            "atlas-hidden": tree.isCollapsible && tree.collapsed,
          })}
        >
          <CheckboxTreeList disabled={disabled} trees={tree.childNodes} />
        </div>
      )}
    </div>
  );
}
