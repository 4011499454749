import { createContext, useContext, useMemo, useState } from "react";
import { Layer, LayerTypeProviderProps } from "../../types";
import {
  ConstraintsStore,
  ConstraintsStoreAction,
  useConstraintsStore,
} from "../ConstraintsLayerType/ConstraintsContext/store";
import { ConstraintsLayer } from "../ConstraintsLayerType";

import { PLANNING_AUTHORITY_LAYER_TYPE_ID } from "./constants";

const isPlanningAuthorityLayerType = (l: Layer): l is ConstraintsLayer =>
  l.layerType.id === PLANNING_AUTHORITY_LAYER_TYPE_ID;

interface PlanningAuthorityLayerTypeContextProps {
  presumptionInFavourFilter: boolean;
  setPresumptionInFavourFilter: React.Dispatch<boolean>;
  localPlanAgeFilter?: number;
  setLocalPlanAgeFilter: React.Dispatch<number | undefined>;
  housingLandSupplyRemainingFilter?: number;
  setHousingLandSupplyRemainingFilter: React.Dispatch<number | undefined>;
  housingDeliveryTestFilter?: number;
  setHousingDeliveryTestFilter: React.Dispatch<number | undefined>;
  constraintsStore: ConstraintsStore;
  dispatch: React.Dispatch<ConstraintsStoreAction>;
}

export const PlanningAuthorityLayerTypeContext = createContext(
  {} as PlanningAuthorityLayerTypeContextProps
);

export const usePlanningAuthorityLayerTypeContext = () =>
  useContext(PlanningAuthorityLayerTypeContext);

export function PlanningAuthorityLayerTypeProvider({
  id,
  children,
  layers,
}: LayerTypeProviderProps) {
  const LOCAL_STORAGE_KEY = `${id}-planning-authority-bundle`;
  const [presumptionInFavourFilter, setPresumptionInFavourFilter] = useState(true);
  const localPlanningLayers = useMemo(() => layers.filter(isPlanningAuthorityLayerType), [layers]);
  const [constraintsStore, dispatch] = useConstraintsStore(LOCAL_STORAGE_KEY, localPlanningLayers);

  const [localPlanAgeFilter, setLocalPlanAgeFilter] = useState<number | undefined>(undefined);
  const [housingLandSupplyRemainingFilter, setHousingLandSupplyRemainingFilter] = useState<
    number | undefined
  >(undefined);
  const [housingDeliveryTestFilter, setHousingDeliveryTestFilter] = useState<number | undefined>(
    undefined
  );

  return (
    <PlanningAuthorityLayerTypeContext.Provider
      value={{
        presumptionInFavourFilter: presumptionInFavourFilter,
        setPresumptionInFavourFilter: setPresumptionInFavourFilter,
        localPlanAgeFilter: localPlanAgeFilter,
        setLocalPlanAgeFilter: setLocalPlanAgeFilter,
        housingLandSupplyRemainingFilter,
        setHousingLandSupplyRemainingFilter,
        housingDeliveryTestFilter: housingDeliveryTestFilter,
        setHousingDeliveryTestFilter: setHousingDeliveryTestFilter,
        constraintsStore,
        dispatch,
      }}
    >
      {children}
    </PlanningAuthorityLayerTypeContext.Provider>
  );
}
