import { useMemo } from "react";
import { StaticMapWithSelection } from "react-migration/domains/sites/card/Map/renderers/StaticMapReact";
import { LayerTypePrintableProps } from "../../../types";
import { renderContourLayer, renderHillshadeLayer } from "../TopographyMapLayer";

type TopographyMapLayerPrintableProps = Pick<LayerTypePrintableProps, "selection" | "onLoaded">;

export function TopographyMapLayerPrintable({
  selection,
  onLoaded,
}: TopographyMapLayerPrintableProps) {
  const hillshadeAndContourLayers = useMemo(() => {
    return [renderHillshadeLayer(), renderContourLayer()];
  }, []);

  return (
    <StaticMapWithSelection
      id="topography-printable"
      layers={hillshadeAndContourLayers}
      onReady={onLoaded}
      selection={selection}
    />
  );
}
