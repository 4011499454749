import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { ModalV2 } from "react-migration/components/ModalV2/ModalV2";
import { PerformanceDebugMenu } from "./PerformanceDebugMenu";
import { CacheDebugMenu } from "./CacheDebugMenu";

export const DebugModal = ({ setDebugModal }: { setDebugModal: (v: boolean) => void }) => {
  const { t } = useTranslation();

  return (
    <ModalV2.Root defaultOpen={true} onOpenChange={() => setDebugModal(false)}>
      <ModalV2.Body
        title={t("nav.nav.debug")}
        className="!atlas-z-[1000]"
        contentClassName="!atlas-max-w-[600px]"
      >
        <PerformanceDebugMenu />
        <CacheDebugMenu />
      </ModalV2.Body>
    </ModalV2.Root>
  );
};
