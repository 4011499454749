import { useEffect, useState } from "react";
import { LayerTypeRightClickMenuProps } from "../../types";
import { getDeckInstance } from "src/js/plugins/deck.gl/deckInstance";
import { getDeckLayerInstanceIds } from "react-migration/lib/map/getLayerInstances";
import { SitesGeoJsonLayer } from "./SitesGeoJsonLayer";
import { sitePickResolver } from "./sitesClickResolver";
import { PickingInfo } from "deck.gl";
import { getSiteById } from "react-migration/domains/sites/hooks/useSiteById";
import { isSiteBoundary } from "./utils";
import { isDefined } from "react-migration/lib/util/isDefined";
import { SiteOverview } from "react-migration/domains/sites/typings/Site";
import { SelectionFeature } from "src/js/stores/map/store";
import { RightClick } from "react-migration/components/RightClick";

interface SiteMenuItem {
  id: string;
  stage: SiteOverview["_stage"];
  title: SiteOverview["title"];

  selectionFeature: SelectionFeature;
}

export function SiteRightClickMenu({
  rightClickLocation,
  setSelection,
  setHoveredFeature,
}: LayerTypeRightClickMenuProps) {
  const [menuItems, setMenuItems] = useState<SiteMenuItem[]>();

  useEffect(() => {
    function getSitePicksFromDeck() {
      const [x, y] = rightClickLocation.deckCoordinate;

      const deck = getDeckInstance();
      const layerIds = getDeckLayerInstanceIds([SitesGeoJsonLayer]);

      return deck?.pickMultipleObjects({ x, y, layerIds });
    }

    async function enrichSitePicks(picks: PickingInfo<unknown>[]) {
      const response = (
        await Promise.all(
          picks.map(async (pick) => {
            if (!isSiteBoundary(pick.object)) return;

            return Promise.all([getSiteById(pick.object.id), sitePickResolver(pick)]);
          })
        )
      )
        .filter(isDefined)
        .map(([site, selectionFeature]) => {
          if (!selectionFeature || !site) return;

          return {
            id: site._id,
            stage: site._stage,
            title: site.title,
            selectionFeature,
          };
        })
        .filter(isDefined);

      setMenuItems(response);
    }

    const sitePicks = getSitePicksFromDeck();
    sitePicks && enrichSitePicks(sitePicks);
  }, [rightClickLocation.deckCoordinate]);

  if (!menuItems?.length) return null;

  return (
    <RightClick.Group title="Saved Sites">
      {menuItems.map((menuItem) => (
        <RightClick.Item
          key={menuItem.id}
          onMouseEnter={() => setHoveredFeature(menuItem.selectionFeature)}
          leadComponent={
            <div
              className="atlas-h-3 atlas-w-3 atlas-rounded"
              style={{ background: menuItem.stage.color }}
            />
          }
          onMouseLeave={() => setHoveredFeature()}
          onClick={() => setSelection(menuItem.selectionFeature)}
        >
          <div className="atlas-truncate atlas-overflow-hidden" title={menuItem.title}>
            {menuItem.title}
          </div>
        </RightClick.Item>
      ))}
    </RightClick.Group>
  );
}
