import { Select } from "react-migration/components/Select";
import { LayerTypeControlPageProps } from "../../types";
import { useSelectedTopographyLayer } from "./useSelectedTopographyLayer";
import { TopographyLayer } from "./constants";
import { ResolutionBadge } from "./ResolutionBadge";

export function TopographyControlPage({ disabled }: LayerTypeControlPageProps) {
  const [selectedLayer, setSelectedLayer] = useSelectedTopographyLayer();

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-2">
      <label className="atlas-text-sm atlas-text-blueGrey-950">Display by</label>
      <Select
        value={selectedLayer}
        disabled={disabled}
        onValueChange={(value) => setSelectedLayer(value as TopographyLayer)}
      >
        <Select.Option
          badge={<ResolutionBadge resolution="1m" />}
          value={TopographyLayer.hillshade}
        >
          Hillshade
        </Select.Option>
        <Select.Option badge={<ResolutionBadge resolution="10m" />} value={TopographyLayer.contour}>
          Contour
        </Select.Option>
        <Select.Option
          badge={<ResolutionBadge resolution="1m & 10m" />}
          value={TopographyLayer.hillshadeContour}
        >
          Hillshade &amp; Contour
        </Select.Option>
      </Select>
    </div>
  );
}
