import { TileLayer, TileLayerProps } from "@deck.gl/geo-layers";
import { BitmapLayer } from "@deck.gl/layers";

type BitmapTileLayerProps = TileLayerProps;

export class BitmapTileLayer extends TileLayer {
  constructor(props: BitmapTileLayerProps) {
    super(props, {
      renderSubLayers: BitmapTileLayer._renderSubLayers,
    });
  }

  static _renderSubLayers: TileLayerProps["renderSubLayers"] = (props) => {
    const { boundingBox } = props.tile;

    return new BitmapLayer(props as any, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data: null,
      image: props.data,
      bounds: [boundingBox[0][0], boundingBox[0][1], boundingBox[1][0], boundingBox[1][1]],
    });
  };
}
