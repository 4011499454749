import { datadogRum } from "@datadog/browser-rum";
import Vue from "vue";
import { User } from "../../stores/user/store";
import { ENVIRONMENT } from "../environment";
import { omit } from "lodash";

export function setupMonitoring({
  applicationId,
  clientToken,
  release,
  service,
  site,
  environment,
  deviceSpecifications,
}: {
  applicationId: string;
  clientToken: string;
  release: string;
  service: string;
  site: string;
  environment: string;
  deviceSpecifications: Record<string, string>;
}) {
  if (applicationId) {
    datadogRum.init({
      allowedTracingUrls: [new URL(ENVIRONMENT.API_URL || location.href).origin],
      applicationId,
      clientToken,
      env: environment === "local" ? "dev" : environment,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      service,
      site,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      version: release,
      beforeSend(event) {
        if (
          /Avoided redundant navigation to current location/.test(
            (event.error as Error)?.message ?? ""
          )
        ) {
          return false;
        }

        return true;
      },
    });
    datadogRum.startSessionReplayRecording();

    datadogRum.addAction(`device-specs`, deviceSpecifications);

    // The Vue-Sentry integration won't log errors to console so doing this instead
    Vue.config.errorHandler = (err, _vm, info) => {
      datadogRum.addError(err, {
        vueError: true,
        info: info,
      });
      console.error(err);
    };
  }
}

export function assignUser(user?: User) {
  if (user) {
    const rawPermissions = omit(user.permissions, "geofencesGeometries");

    datadogRum.setUser({
      id: user._id as string,
      email: user.email as string,
      plan: user.licence_summary,
      permissions: rawPermissions,
    });
    datadogRum.setGlobalContextProperty("licence-summary", user.licence_summary);
    (user.features || []).forEach((feature) =>
      datadogRum.setGlobalContextProperty(`feature-${feature}`, true)
    );
    (user.beta_features || []).forEach((betaFeature) =>
      datadogRum.setGlobalContextProperty(`beta-feature-${betaFeature}`, true)
    );
  }
}
