import { useCallback, useEffect, useMemo, useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { Geometry, MultiPolygon, Polygon } from "@turf/helpers";
import cn from "classnames";
import { DeprecatedTooltip, Theme } from "@landtechnologies/components";
import { LogEventCreatedSiteEventLocation } from "react-migration/lib/util/logEventV2";
import { filterSitesInGeofence } from "react-migration/domains/geofencing/utils/filterSitesInGeofence";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { MergeSiteModal } from "../MergeSiteModal";
import { StageSelectPopoverContent } from "./StageSelect";
import { CreatedSite, useSaveSiteMutation } from "./useSaveSiteMutation/useSaveSiteMutation";
import { useUpdateSiteMutation } from "./useUpdateSiteMutation/useUpdateSiteMutation";
import { useCheckStages } from "./useCheckStages";
import { useIntersectingSitesInViewport } from "react-migration/layouts/map/Multilayer/layer_types/SitesLayerType/useIntersectingSitesInViewport";
import { mergeSiteGeometries } from "react-migration/domains/sites/helpers/mergeSiteGeometries";
import { Nullable } from "src/js/types/Nullable";
import { SaveSiteLayer } from "../../typings/SaveSiteLayer";
import { Site } from "../../typings/Site";
import { SaveButton, SaveButtonState } from "./Buttons";
import { SelectionType } from "src/js/stores/map/store";
import { cleanTypename } from "react-migration/lib/util/cleanTypename";
import { isDefined } from "react-migration/lib/util/isDefined";
import { parseSiteGeometry } from "./utils/parseSiteGeometry";
import { ErrorPopover } from "react-migration/components/ErrorPopover";
import { isUndefined } from "lodash";
import { SiteGeometry } from "./typings/Site";

export function isValidSiteGeometry(geom?: Nullable<Geometry>): geom is MultiPolygon | Polygon {
  if (!geom) return false;
  if (geom.type !== "Polygon" && geom.type !== "MultiPolygon") return false;
  return true;
}

export interface SaveSiteButtonProps {
  layer: SaveSiteLayer;
  source: LogEventCreatedSiteEventLocation;
  selectionType?: SelectionType;
  text?: string;
  siteGeometry?: GeoJSON.Point | GeoJSON.Polygon | GeoJSON.MultiPolygon;
  planning_alert_result?: string;
  handleSaveSites?: (savedSites: CreatedSite[]) => void;
  assignees?: string[];
  tidbit?: {
    planning_application: {
      ref: string;
      gss_code: string;
    };
  };
  coordId?: string;
  title?: string;
  disableDryRun?: boolean;
  siteMatchType?: "EXACT" | "INTERSECTS_POINT";
  mergeSiteOnSave?: boolean;
}

export function SaveSiteButton({
  handleSaveSites,
  layer,
  source,
  selectionType,
  siteGeometry: siteGeometryInput,
  text,
  planning_alert_result,
  assignees,
  tidbit,
  coordId,
  title,
  disableDryRun,
  siteMatchType,
  mergeSiteOnSave = false,
}: SaveSiteButtonProps) {
  const siteToSave = useMemo(
    () => ({
      geometry: parseSiteGeometry(siteGeometryInput),
      source: { layer, planning_alert_result, title },
      assignees,
      tidbit,
      title,
      id: coordId,
    }),
    [assignees, coordId, layer, planning_alert_result, siteGeometryInput, tidbit, title]
  );

  /** Used in `useCheckStages` & `useSaveSiteMutation` hooks. */
  const sitesToSave = useMemo(
    () =>
      siteToSave.geometry ? [{ ...siteToSave, geometry: siteToSave.geometry as SiteGeometry }] : [],
    [siteToSave]
  );

  const { t } = useTranslation();
  const [isInsideGeo, setIsInsideGeo] = useState(true);
  const [showStageSelectPopover, setShowStageSelectPopover] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [hasMergeError, setHasMergeError] = useState(false);
  const intersectingSites = useIntersectingSitesInViewport(siteToSave.geometry || undefined);

  const {
    isError,
    data,
    loading,
    selectedStageId,
    setSelectedStageId,
    stages,
    isStagesLoading,
    networkStatus,
  } = useCheckStages({
    sites: sitesToSave,
    siteMatchType,
    disableDryRun,
  });

  const savable = useMemo(
    () => !!(selectedStageId && siteToSave.geometry),
    [selectedStageId, siteToSave.geometry]
  );

  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [updateSite, { loading: isUpdating }] = useUpdateSiteMutation();
  const [saveSite, creatingSiteState] = useSaveSiteMutation({
    layer,
    source,
    selectionType,
    sites: sitesToSave,
    stageId: selectedStageId,
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (creatingSiteState.error) {
      timeout = setTimeout(() => {
        creatingSiteState.reset();
      }, 5000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [creatingSiteState]);

  const disabled = useMemo(
    () => creatingSiteState.loading || !savable || !isInsideGeo || isUpdating,
    [creatingSiteState, savable, isInsideGeo, isUpdating]
  );

  useEffect(() => {
    const sitesInsideGeo = filterSitesInGeofence([{ geometry: siteToSave.geometry }]);
    if (!sitesInsideGeo.length) {
      setIsInsideGeo(false);
    }
  }, [siteToSave.geometry]);

  const updateExistingSiteStage = async (existingSite: Site) =>
    updateSite({
      variables: {
        updateData: {
          _stage: selectedStageId,
          _id: existingSite?._id,
        },
      },
    });

  const saveNewSite = async () => {
    try {
      const saveSiteResponse = await saveSite();
      const savedSites = saveSiteResponse?.data?.createSites
        ?.map((x) => cleanTypename(x.saved_site))
        .filter(isDefined);

      if (!savedSites?.length) {
        throw new Error("No sites were saved");
      }

      setIsSaved(true);

      savedSites && handleSaveSites?.(savedSites);
    } catch (error) {
      console.error(error);
      setIsSaved(false);
    }
  };

  const mergeWithSite = useCallback(
    async (siteId: string) => {
      setHasMergeError(false);

      try {
        const siteToMerge = intersectingSites.find((s) => s.id === siteId);
        const siteToMergeGeometry = siteToMerge?.geometry;

        if (
          !siteToSave.geometry ||
          !siteToMerge ||
          !isValidSiteGeometry(siteToSave.geometry) ||
          !isValidSiteGeometry(siteToMergeGeometry)
        )
          return;

        const mergedSiteGeometry = mergeSiteGeometries(
          siteToSave.geometry,
          siteToMergeGeometry
        ).geometry;

        if (!mergedSiteGeometry) throw new Error("Failed to merge site geometries.");
        const title = siteToMerge.properties?.title || undefined;

        const { data } = await updateSite({
          variables: {
            updateData: {
              _id: String(siteToMerge.id),
              title,
              geometry: mergedSiteGeometry,
            },
          },
        });

        const updatedSite = data?.updateSite.site;

        updatedSite && handleSaveSites?.([updatedSite]);
        setIsSaved(true);
      } catch (error) {
        if (error instanceof Error) console.error(error.message);
        else console.error(`Encountered a problem merging with site (id: ${siteId}).`);
        setHasMergeError(true);

        setIsSaved(false);
      }

      setShowStageSelectPopover(false);
    },
    [handleSaveSites, intersectingSites, siteToSave.geometry, updateSite]
  );

  const onSaveHandler = () => {
    setShowStageSelectPopover(false);

    const existingSavedSite = data?.createSitesDryRun[0].saved_site;

    if (existingSavedSite) {
      updateExistingSiteStage(existingSavedSite);
    } else if (mergeSiteOnSave && intersectingSites.length) {
      setShowMergeModal(true);
    } else {
      saveNewSite();
    }
  };

  const onExpandStagePopover = useCallback(() => setShowStageSelectPopover(true), []);

  const isLoading = creatingSiteState.loading || loading || isStagesLoading;
  const isSaveable = siteToSave.geometry && !(isSaved || data?.createSitesDryRun[0].saved_site);

  const buttonState: SaveButtonState =
    siteToSave.geometry === false
      ? SaveButtonState.ERROR
      : isLoading
      ? SaveButtonState.LOADING
      : isSaveable
      ? SaveButtonState.IDLE
      : SaveButtonState.SAVED;

  if (isUndefined(siteToSave.geometry) || isError) return null;

  // Note: Dynamic colours used for buttons here to match legacy component
  // until the legacy save site component is replaced - use pink-500/700 after
  return (
    <>
      <DeprecatedTooltip
        title={
          isInsideGeo
            ? t("sites.save_site.save_site_dropdown.save")
            : t("sites.save_site.save_site.search_results_outside_of_geofence")
        }
        placement="bottom"
        theme={Theme.Dark}
        disabled={isInsideGeo}
      >
        <Popover.Root open={showStageSelectPopover} onOpenChange={setShowStageSelectPopover}>
          <Popover.Anchor>
            <SaveButton
              buttonState={buttonState}
              onSave={onSaveHandler}
              copy={{ save: text }}
              onExpand={onExpandStagePopover}
            />
          </Popover.Anchor>

          <Popover.Portal>
            <Popover.Content
              className="atlas-flex atlas-flex-col atlas-w-screen md:atlas-w-60 atlas-gap-y-4 atlas-p-4 atlas-bg-white atlas-rounded atlas-border atlas-border-neutral-100 atlas-drop-shadow-xl"
              align="center"
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
            >
              <StageSelectPopoverContent
                setSelectedStageId={setSelectedStageId}
                selectedStageId={selectedStageId}
                stages={stages}
                loading={isStagesLoading}
                networkStatus={networkStatus}
                saveHandler={onSaveHandler}
                closeHandler={() => setShowStageSelectPopover(false)}
              />
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
        <ErrorPopover.Root open={!!creatingSiteState.error}>
          <ErrorPopover.Anchor />
          <ErrorPopover.Portal>
            <ErrorPopover.Content variant="ERROR" side="bottom" align="end">
              <ErrorPopover.Header>{t("sites.save_site.error_header")}</ErrorPopover.Header>
              <ErrorPopover.Body>{t("sites.save_site.error_body")}</ErrorPopover.Body>
            </ErrorPopover.Content>
          </ErrorPopover.Portal>
        </ErrorPopover.Root>
      </DeprecatedTooltip>
      {showMergeModal && (
        <MergeSiteModal
          disabled={disabled}
          intersectingSites={intersectingSites}
          handleCancelSave={() => setShowMergeModal(false)}
          handleSaveAsSiteAs={saveNewSite}
          handleMergeSave={mergeWithSite}
          hasError={hasMergeError}
          setHasError={setHasMergeError}
        />
      )}
    </>
  );
}
