import { CheckboxTreeList, CheckboxTreeNode } from "./CollapsibleCheckboxTree";
import { ToggleAllButton } from "./ToggleAllButton";
import { CheckboxTreeChange, HatchComponent, TreeSchema, TreeStructure } from "./types";
import { useCollapsibleCheckboxTree } from "./useCollapsibleCheckboxTree";
import { noop } from "lodash";

interface CheckboxTreeProps {
  /** An individual tree level. Contains basic meta including id, display
   * name etc. Children are inside optional nodes array. */
  treeStructure: TreeStructure;
  /** Used to control the rendered output. Primarily used
   * to control the rendered depth of the checkbox tree & ordering — described
   * in the tests & storybook. */
  treeSchema: TreeSchema[];
  /** The controlled checkbox tree state. */
  visibleNodeIds: string[];
  /** A callback to control checkbox tree state.  */
  onChange: CheckboxTreeChange;
  /** Include a swatch beside the label text — documented in component story. */
  Hatch?: HatchComponent;
  /** Get and set collapse state of a given node */
  getCollapsed?: (id: string) => boolean;
  setCollapsed?: (id: string, value: boolean) => void;

  disabled?: boolean;
  title?: JSX.Element | string;
  columns?: boolean;
}

export function CheckboxTree({
  treeStructure,
  treeSchema,
  visibleNodeIds,
  onChange,
  Hatch,
  disabled,
  title,
  getCollapsed = () => false,
  setCollapsed = noop,
  columns,
}: Readonly<CheckboxTreeProps>) {
  const checkboxTree = useCollapsibleCheckboxTree(treeStructure, {
    treeSchema,
    visibleNodeIds,
    Hatch,
    onChange,
    getCollapsed,
    setCollapsed,
  });

  const isTopLevel = !treeSchema || treeSchema?.map((s) => s.key).includes(treeStructure.id);

  return (
    <div className="atlas-flex atlas-flex-col">
      <div className="atlas-flex atlas-h-10 atlas-items-center atlas-justify-between">
        <div>{title}</div>
        <ToggleAllButton category={checkboxTree} disabled={disabled} />
      </div>
      {isTopLevel ? (
        <CheckboxTreeNode tree={checkboxTree} disabled={disabled} />
      ) : (
        <CheckboxTreeList trees={checkboxTree.childNodes} disabled={disabled} columns={columns} />
      )}
    </div>
  );
}
