import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { routedClient } from "react-migration/lib/persistence/apollo";
import { Title } from "../../typings/Title";
import { titlePolyByLocation } from ".";
import {
  OwnershipIntersectableGeometry,
  TitlesByLocationQueryData,
  TitlesByLocationQueryVariables,
} from "../useTitlesByLocationQuery";
import { Nullable } from "src/js/types/Nullable";
import { generateMergedPolygon } from "react-migration/lib/util/generateMergedPolygon";
import { isDefined } from "react-migration/lib/util/isDefined";

/**
 * Takes a clicked geometry and returns a list of titles
 * @param location: OwnershipIntersectableGeometry
 * @returns { titlesByLocation, queryResult, loading }
 */

export function useTitlePolysByLocation(location?: Nullable<OwnershipIntersectableGeometry>) {
  const { data, loading, ...queryResult } = useQuery<
    TitlesByLocationQueryData,
    TitlesByLocationQueryVariables
  >(titlePolyByLocation, {
    variables: { location: location! },
    skip: !location,
    client: routedClient,
  });

  const titles = useMemo(
    () =>
      data
        ? data.titlesByLocation.map((title) => ({
            ...title,
            mergedTitlePolygon: mergeTitlePolygons(title),
          }))
        : [],
    [data]
  );

  return { titlesByLocation: titles, queryResult, loading };
}

function mergeTitlePolygons(title: Title) {
  const titlePolygons = title?.polygons?.map((g) => g.geometry).filter(isDefined);

  return titlePolygons && generateMergedPolygon(titlePolygons);
}
