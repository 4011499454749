import { planningTypes } from "react-migration/domains/planning/constants";
import { createPlanningApplicationLayerFilters } from "react-migration/domains/planning/map/layers/PlanningApplicationLayer/planningFilters";
import { usePlanningFilterState } from "../state/usePlanningFilterState";
import { useMemo } from "react";
import type {
  ClassAndTypeFilters,
  PlanningTypesFilters,
} from "react-migration/domains/planning/types";

export type PlanningFilters = ReturnType<typeof usePlanningFilterState>[0];

export function useClassAndTypeFilters(filters: PlanningFilters) {
  const {
    showCommercial,
    enabledTypes,
    enabledStatuses,
    minSize,
    showOther,
    showResidential,
    maxYear,
  } = filters;

  return useMemo(() => {
    const filters: ClassAndTypeFilters = {
      showCommercial,
      showResidential,
      showOther,
      minSize,
      maxYear,
      planningTypes: Object.fromEntries(
        planningTypes.map((planningType) => [
          planningType.id,
          enabledTypes.includes(planningType.id),
        ])
      ) as PlanningTypesFilters,
      // TODO: Investigate how to control these
      showMinor: true,
      visiblePlanningApplicationIds: [],
      enabledStatuses,
      appToHide: "",
    };

    return [filters, createPlanningApplicationLayerFilters(filters)] as const;
  }, [showCommercial, enabledTypes, minSize, showOther, showResidential, maxYear, enabledStatuses]);
}
