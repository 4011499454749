import { LayerTypeRightClickMenuProps } from "../../types";
import { RightClick } from "react-migration/components/RightClick";
import { ComponentProps, useEffect, useMemo, useState } from "react";
import { PlanningApplicationClassification } from "react-migration/domains/planning/components/PlanningApplicationClassification";
import { PlanningApplicationLayer } from "react-migration/domains/planning/map/layers/PlanningApplicationLayer/PlanningApplicationLayer";
import { getDeckInstance } from "src/js/plugins/deck.gl/deckInstance";
import { getDeckLayerInstanceIds } from "react-migration/lib/map/getLayerInstances";
import {
  PlanningApplicationFeature,
  PlanningClassification,
  StatusDerived,
} from "react-migration/domains/planning/types";
import { fetchPlanningApplicationById } from "./usePlanningAppsById";
import { planningApplicationsClickResolver } from "./planningClickResolver";
import { PickingInfo } from "deck.gl";
import { isDefined } from "react-migration/lib/util/isDefined";
import { SelectionFeature } from "src/js/stores/map/store";
import { feature } from "@turf/turf";
import { Nullable } from "src/js/types/Nullable";
import { LTIcon } from "react-migration/components/LTIcon";
import { Tag, TagColor } from "react-migration/components/Tag";
import format from "date-fns/format";

interface PlanningApplicationMenuItem {
  id: string;
  statusDerived: Nullable<StatusDerived>;
  classification: PlanningClassification;
  ref: string;
  selectionFeature: SelectionFeature;
  dateReceived: string;
}

export function PlanningApplicationRightClickMenu({
  rightClickLocation,
  setSelection,
  setHoveredFeature,
}: LayerTypeRightClickMenuProps) {
  const [planningApplications, setPlanningApplications] = useState<PlanningApplicationMenuItem[]>();

  useEffect(() => {
    function getPlanningPicksFromDeck() {
      const [x, y] = rightClickLocation.deckCoordinate;

      const deck = getDeckInstance();
      const layerIds = getDeckLayerInstanceIds([PlanningApplicationLayer]);

      return deck?.pickMultipleObjects({ x, y, layerIds });
    }

    async function enrichPlanningApplicationPicks(
      picks: PickingInfo<PlanningApplicationFeature>[]
    ) {
      const response = (
        await Promise.all(
          picks.map(async (pick) => {
            if (!pick.object) return;

            return Promise.all([
              fetchPlanningApplicationById(pick.object.properties.id),
              planningApplicationsClickResolver(pick),
            ]);
          })
        )
      )
        .filter(isDefined)
        .map(([planningApplication, selectionFeature]) => {
          if (!selectionFeature) return;

          return {
            id: planningApplication.id,
            classification: planningApplication.classification,
            ref: planningApplication.ref,
            dateReceived: planningApplication.date_received,
            selectionFeature,
            statusDerived: planningApplication.status_derived,
          };
        })
        .filter(isDefined);

      setPlanningApplications(response);
    }

    const planningPicks = getPlanningPicksFromDeck();
    planningPicks && enrichPlanningApplicationPicks(planningPicks);
  }, [rightClickLocation.deckCoordinate]);

  const bucketedApplications = useMemo(() => {
    const sortedApplications = planningApplications?.sort(
      (a, b) => new Date(b.dateReceived).getTime() - new Date(a.dateReceived).getTime()
    );

    if (!sortedApplications?.length) return [null, null];

    return [sortedApplications.slice(0, 4), sortedApplications.slice(4)];
  }, [planningApplications]);

  if (!bucketedApplications[0]?.length) return null;

  return (
    <RightClick.Group title="Planning Applications">
      {bucketedApplications[0].map((planningApplication) => (
        <PlanningApplicationItem
          key={planningApplication.id}
          planningApplication={planningApplication}
          setHoveredFeature={setHoveredFeature}
          setSelection={setSelection}
        />
      ))}

      {!!bucketedApplications[1]?.length && (
        <RightClick.Sub>
          <RightClick.SubTrigger>{bucketedApplications[1].length} more</RightClick.SubTrigger>
          <RightClick.Portal>
            <RightClick.SubContent>
              {bucketedApplications[1].map((planningApplication) => (
                <PlanningApplicationItem
                  key={planningApplication.id}
                  planningApplication={planningApplication}
                  setHoveredFeature={setHoveredFeature}
                  setSelection={setSelection}
                />
              ))}
            </RightClick.SubContent>
          </RightClick.Portal>
        </RightClick.Sub>
      )}
    </RightClick.Group>
  );
}

interface PlanningApplicationItemProps {
  planningApplication: PlanningApplicationMenuItem;
  setSelection(selection: SelectionFeature): void;
  setHoveredFeature(selection?: SelectionFeature): void;
}

function PlanningApplicationItem({
  planningApplication,
  setHoveredFeature,
  setSelection,
}: PlanningApplicationItemProps) {
  const renderGeometry = planningApplication.selectionFeature.__renderGeometry?.geometry;

  return (
    <RightClick.Item
      onClick={() => setSelection(planningApplication.selectionFeature)}
      onMouseEnter={() =>
        setHoveredFeature({
          ...planningApplication.selectionFeature,
          feature: renderGeometry
            ? feature(renderGeometry)
            : planningApplication.selectionFeature.feature,
        })
      }
      onMouseLeave={() => setHoveredFeature()}
      leadComponent={
        <PlanningApplicationClassification classification={planningApplication.classification} />
      }
      tailComponent={
        <div className="atlas-flex atlas-items-center atlas-gap-2">
          <div
            className="atlas-text-blueGrey-400 atlas-text-sm"
            title={format(new Date(planningApplication.dateReceived), "dd/MM/yyyy")}
          >
            {format(new Date(planningApplication.dateReceived), "MM/yy")}
          </div>
          <StatusDerivedTag statusDerived={planningApplication.statusDerived} />
        </div>
      }
    >
      <div className="atlas-flex atlas-justify-between">
        <div>{planningApplication.ref}</div>
      </div>
    </RightClick.Item>
  );
}

interface StatusDerivedTagProps {
  statusDerived: Nullable<StatusDerived>;
}

function StatusDerivedTag({ statusDerived }: StatusDerivedTagProps) {
  const tagProps = useMemo<Pick<ComponentProps<typeof Tag>, "color" | "tag" | "title">>(() => {
    switch (statusDerived) {
      case StatusDerived.APPROVED:
        return {
          color: TagColor.GREEN,
          tag: <LTIcon icon="lt-check-line" />,
          title: "Status: Approved",
        };

      case StatusDerived.REJECTED:
        return {
          color: TagColor.RED,
          tag: <LTIcon icon="lt-close-line" />,
          title: "Status: Rejected",
        };

      case StatusDerived.PENDING:
        return {
          color: TagColor.ORANGE,
          tag: <LTIcon icon="lt-clock-line" />,
          title: "Status: Pending",
        };

      case StatusDerived.WITHDRAWN:
        return {
          color: TagColor.LIGHT_GRAY,
          tag: <LTIcon icon="lt-close-line" />,
          title: "Status: Withdrawn",
        };

      default:
        return {
          color: TagColor.LIGHT_GRAY,
          tag: <LTIcon icon="lt-question-line" />,
          title: "Status: Unknown",
        };
    }
  }, [statusDerived]);

  return <Tag {...tagProps} className="atlas-px-1" />;
}
