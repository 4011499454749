import { useCallback, useMemo } from "react";
import { useSnapshot } from "valtio";
import type { Layer } from "../types";
import Feature from "src/js/stores/user/Feature";
import { userStore } from "src/js/stores/user/store";

function checkFeatureAccess(userFeatures: Feature[], requiredFeatures: Feature[]) {
  return requiredFeatures.some((feature) => userFeatures.includes(feature));
}

export function useLayerAccess(layers: Layer[]) {
  const checkLayerAccess = useCheckLayerAccess();

  return useMemo(() => layers.filter(checkLayerAccess), [checkLayerAccess, layers]);
}

export function useCheckLayerAccess() {
  const {
    user: { beta_features, features },
  } = useSnapshot(userStore);

  return useCallback(
    (layer: Layer) =>
      !layer.requiredFeatureAccess?.length ||
      checkFeatureAccess([...beta_features, ...features], layer.requiredFeatureAccess),
    [beta_features, features]
  );
}
