import { LayerTypeRightClickMenuProps } from "../../types";
import { useTitlePolysByLocation } from "react-migration/domains/ownership/apollo/useTitlePolysByLocation/useTitlePolysByLocation";
import { feature, point } from "@turf/turf";
import { RightClick } from "react-migration/components/RightClick";
import { useCallback, useMemo } from "react";

import { TenureType } from "react-migration/domains/ownership/typings/Tenure";
import { LtIcons } from "@landtechnologies/icons/font/lt-icons";
import { SelectionFeature, SelectionType } from "src/js/stores/map/store";

type Title = ReturnType<typeof useTitlePolysByLocation>["titlesByLocation"][0];

export function OwnershipRightClickMenu({
  rightClickLocation,
  setSelection,
  setHoveredFeature,
}: LayerTypeRightClickMenuProps) {
  const { mapCoordinate: clickedCoordinate } = rightClickLocation;

  const location = useMemo(
    () => point([clickedCoordinate[0], clickedCoordinate[1]]),
    [clickedCoordinate]
  );
  const { titlesByLocation } = useTitlePolysByLocation(location.geometry);

  const titleSelectionHandler = useCallback(
    (title: Title) => setSelection(createTitleSelection(title)),
    [setSelection]
  );

  const [firstBucket, secondBucket] = useMemo(() => {
    if (!titlesByLocation.length) return [null, null];

    return [titlesByLocation.slice(0, 4), titlesByLocation.slice(4)];
  }, [titlesByLocation]);

  if (!firstBucket?.length) return null;

  return (
    <RightClick.Group title="Ownership Titles">
      {firstBucket.map((title) => (
        <TitleItem
          key={title.title_no}
          title={title}
          setHoveredFeature={setHoveredFeature}
          titleSelectionHandler={titleSelectionHandler}
        />
      ))}

      {!!secondBucket?.length && (
        <RightClick.Sub>
          <RightClick.SubTrigger>{secondBucket.length} more</RightClick.SubTrigger>
          <RightClick.Portal>
            <RightClick.SubContent>
              {secondBucket.map((title) => (
                <TitleItem
                  key={title.title_no}
                  title={title}
                  setHoveredFeature={setHoveredFeature}
                  titleSelectionHandler={titleSelectionHandler}
                />
              ))}
            </RightClick.SubContent>
          </RightClick.Portal>
        </RightClick.Sub>
      )}
    </RightClick.Group>
  );
}

interface TitleItemProps {
  title: Title;
  setHoveredFeature(selection?: SelectionFeature): void;
  titleSelectionHandler(title: Title): void;
}

function TitleItem({ title, setHoveredFeature, titleSelectionHandler }: TitleItemProps) {
  if (!title.title_no) return null;

  return (
    <RightClick.Item
      leadIcon={getLeadIcon(title.tenure)}
      onMouseEnter={() => setHoveredFeature(createTitleSelection(title))}
      onMouseLeave={() => setHoveredFeature(undefined)}
      onClick={() => titleSelectionHandler(title)}
    >
      {title.title_no}
    </RightClick.Item>
  );
}

function getLeadIcon(tenure?: TenureType): `${LtIcons}` | undefined {
  switch (tenure) {
    case TenureType.FREEHOLD:
      return "lt-freehold";
    case TenureType.LEASEHOLD:
      return "lt-leasehold";
    default:
      return "lt-unknown-tenure";
  }
}

function createTitleSelection(title: Title): SelectionFeature | undefined {
  if (!title.title_no || !title.mergedTitlePolygon) return;

  return {
    type: SelectionType.OWNERSHIP_TITLE,
    id: title.title_no,
    feature: feature(title.mergedTitlePolygon),
  };
}
