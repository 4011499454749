import { useCallback } from "react";
import { logEventV2 } from "react-migration/lib/util/logEventV2";
import { useDebounceCallback } from "react-migration/lib/util/useDebounceCallback";

export type TrackControlChange = (values: { filterName: string; filterState: any }) => void;

export const usePlanningTracking = (cardName: string) => {
  const trackControlChange = useCallback<TrackControlChange>(
    ({ filterName, filterState }) => {
      logEventV2({
        name: "Workbench filter click",
        properties: {
          cardName: cardName,
          filterName,
          filterState,
        },
      });
    },
    [cardName]
  );

  const debouncedTrackControlChange = useDebounceCallback(trackControlChange, 500);

  return { debouncedTrackControlChange, trackControlChange };
};
