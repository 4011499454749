import clsx from "clsx";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export enum ActionGroups {
  Cache = "cache",
  Performance = "performance",
}

export enum Actions {
  ClearCache = "cache",
  ClearLocalStorage = "local_storage",
  Raster = "raster",
  RasterOwnership = "raster_ownership",
  HiddenLayers = "hidden",
  HiddenSites = "hidden_sites",
  VectorAPI = "vector",
  Logging = "logging",
}

type ActionType = PropsWithChildren & { name: string; group: string; action?: () => void };

export const Action = ({ group, name, action, children }: ActionType) => {
  const { t } = useTranslation();

  return (
    <li
      className={clsx(
        "atlas-flex atlas-items-center atlas-justify-between atlas-py-4 atlas-px-3 hover:atlas-bg-platform-50",
        action ? " atlas-cursor-pointer" : ""
      )}
      onClick={action}
    >
      <div className="atlas-flex atlas-flex-col">
        <p className="atlas-font-medium atlas-text-neutral-900 atlas-text-sm/6">
          {t(`nav.debug_modal.action_group.${group}.action.${name}.title`)}
        </p>
        <p className="atlas-text-xs atlas-text-neutral-500">
          {t(`nav.debug_modal.action_group.${group}.action.${name}.description`)}
        </p>
      </div>
      <div className="atlas-shrink-0 atlas-min-w-[120px] atlas-flex atlas-justify-end">
        {children}
      </div>
    </li>
  );
};

type ActionGroupType = PropsWithChildren & { name: string };
export const ActionGroup = ({ name, children }: ActionGroupType) => {
  const { t } = useTranslation();
  return (
    <div className="atlas-py-2">
      <div>
        <h2 className="atlas-text-lg/6 atlas-font-medium atlas-text-neutral-900">
          {t(`nav.debug_modal.action_group.${name}.title`)}
        </h2>
        <p className="atlas-mt-1 atlas-text-sm atlas-text-neutral-500">
          {t(`nav.debug_modal.action_group.${name}.description`)}
        </p>
      </div>
      <ul role="list" className="atlas-mt-2 atlas-divide-y atlas-divide-neutral-200">
        {children}
      </ul>
    </div>
  );
};
