import { useLocalStorage } from "usehooks-ts";
import {
  DEFAULT_TOPOGRAPHY_LAYER,
  SELECTED_TOPOGRAPHY_LAYER_LOCAL_STORAGE_KEY,
  TopographyLayer,
} from "./constants";
import { SetStateAction } from "react";

export const useSelectedTopographyLayer: () => [
  TopographyLayer,
  (value: SetStateAction<TopographyLayer>) => void
] = () =>
  useLocalStorage<TopographyLayer>(
    SELECTED_TOPOGRAPHY_LAYER_LOCAL_STORAGE_KEY,
    DEFAULT_TOPOGRAPHY_LAYER
  );
