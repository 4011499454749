import { useMemo } from "react";

import { Avatar, AvatarProps, AvatarSize } from "../Avatar";

export enum AvatarsSize {
  SM = "sm",
  MD = "md",
  LG = "lg",
}

interface AvatarItem extends Omit<AvatarProps, "size"> {
  id?: string;
}

export interface AvatarsProps {
  avatars: AvatarItem[];
  placeholder?: string;
  truncateCount?: number;
  size?: AvatarsSize;
}

const avatarsToAvatarSizeMap = {
  [AvatarsSize.SM]: AvatarSize.XS,
  [AvatarsSize.MD]: AvatarSize.SM,
  [AvatarsSize.LG]: AvatarSize.MD,
};

export function Avatars({
  avatars,
  placeholder,
  truncateCount = 3,
  size = AvatarsSize.MD,
}: AvatarsProps) {
  const displayAvatars = useMemo(() => {
    return avatars.slice(0, truncateCount);
  }, [avatars, truncateCount]);

  const remainingCount = useMemo(() => {
    return avatars.length - truncateCount;
  }, [avatars, truncateCount]);

  const avatarSize = avatarsToAvatarSizeMap[size];

  return (
    <div
      data-testid="assignee-container"
      className="atlas-flex atlas-justify-start atlas-items-center"
    >
      {displayAvatars.length > 0
        ? displayAvatars
            .map(({ id, firstName, lastName }, index) => (
              <div
                key={id ?? index}
                data-testid={`avatar-${id ?? index}`}
                className={`-atlas-mr-2 atlas-z-${(displayAvatars.length - index) * 10}`}
              >
                <Avatar firstName={firstName} lastName={lastName} size={avatarSize} />
              </div>
            ))
            .filter(Boolean)
        : placeholder && (
            <span className="atlas-text-blueGrey-900 atlas-text-xs atlas-font-medium">
              {placeholder}
            </span>
          )}
      {remainingCount > 0 && (
        <div data-testid="remaining-count">
          <Avatar key="remaining" content={`+${remainingCount}`} size={avatarSize} />
        </div>
      )}
    </div>
  );
}
