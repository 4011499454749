import { ScaleLinear } from "d3-scale";
import { Color } from "deck.gl";
import clsx from "clsx";
import { Select } from "react-migration/components/Select";
import { LayerTypeControlPageProps } from "../../types";
import { useDemographicsContext } from "./DemographicsProvider";
import { DemographicsLayerConfig } from "./DemographicsLayerType";
import { ScaleLinearLegend } from "./ScaleLinearLegend";
import { DemographicsDialog } from "./DemographicsDialog";
import { DEMOGRAPHIC_STYLE_MAP, DemographicsStyles } from "./constants";
import { useDemographicSubCategory } from "./useDemographicSubCategory";
import { Alert } from "@landtechnologies/components";
import { useControlTracking } from "../../ControlTrackingContext";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isColorScaleLinear(f: any): f is ScaleLinear<Color, Color, Color> {
  // TODO: Make this better
  return !!f?.domain?.();
}

export const DemographicsControls = ({ layer, disabled }: LayerTypeControlPageProps) => {
  const { selectedAttribute, setSelectedAttribute } = useDemographicsContext();
  const { debouncedTrackControlChange } = useControlTracking();
  const layerConfig = layer.layerConfig as DemographicsLayerConfig;

  const selectedStyleOption = layerConfig.attributes_style_options.find(
    (a) => a?.attribute === selectedAttribute
  );
  const selectedStyle = selectedStyleOption?.style;

  const subCategoryConditions =
    selectedStyleOption?.customConditions || layerConfig.subCategoryConditions;

  const { alert } = useDemographicSubCategory(subCategoryConditions);

  const fillStyleOverride = selectedStyle && DEMOGRAPHIC_STYLE_MAP[selectedStyle]?.fillColor;
  const formatter = selectedStyle && DEMOGRAPHIC_STYLE_MAP[selectedStyle]?.labelFormatter;
  const ticks = selectedStyle && DEMOGRAPHIC_STYLE_MAP[selectedStyle]?.ticks;

  return (
    <div className="atlas-flex-col atlas-space-y-1">
      <DemographicsDialog label={"Display by"} region={layerConfig.region} disabled={disabled} />
      <Select
        value={selectedAttribute}
        onValueChange={(value) => {
          debouncedTrackControlChange({
            filterName: "Demographics attribute",
            filterState: value,
          });
          setSelectedAttribute(value as DemographicsStyles);
        }}
        disabled={disabled}
      >
        <Select.Option value="output_areas">Output areas</Select.Option>
        {layerConfig.attributes_style_options.map((a, index) =>
          a ? (
            <Select.Option key={a.attribute} value={a.attribute}>
              {a.displayName}
            </Select.Option>
          ) : (
            <Select.Seperator key={index} />
          )
        )}
      </Select>
      {alert && <Alert.Warning>{alert}</Alert.Warning>}
      {isColorScaleLinear(fillStyleOverride) && (
        <ScaleLinearLegend
          scaleLinear={fillStyleOverride}
          formatter={formatter}
          tickOverrides={ticks}
          className={clsx({
            "atlas-opacity-50": disabled,
          })}
        />
      )}
    </div>
  );
};
