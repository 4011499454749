import { useEffect, useId } from "react";
import { useHasPerfSetting } from "react-migration/domains/nav/components/Modals/DebugModal/performanceSettingsStore";
import { removeDeckLayer, upsertDeckLayer } from "src/js/stores/map/actions/updateDeckSettings";
import type { DeckLayer } from "src/js/stores/map/store";

type CreateLayerFn = () => Omit<DeckLayer, "key">;

export function useMapLayer(createLayer: CreateLayerFn, deps: unknown[], visible?: boolean) {
  const key = useId();

  const hasRemoveInvisibleLayers = useHasPerfSetting("removeInvisibleLayers");

  useEffect(() => {
    if (hasRemoveInvisibleLayers && visible === false) {
      removeDeckLayer(key);
    } else {
      upsertDeckLayer({ ...createLayer(), key });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRemoveInvisibleLayers, visible, ...deps]);

  useEffect(() => {
    return () => removeDeckLayer(key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
