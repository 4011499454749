import { useMapLayer } from "react-migration/lib/map/useMapLayer";

import { ENVIRONMENT } from "src/js/util/environment";
import { PlanningApplicationLayer } from "react-migration/domains/planning/map/layers/PlanningApplicationLayer/PlanningApplicationLayer";
import { usePlanningApplicationsContext } from "./Context";
import { LayerTypeMapLayerProps } from "../../types";
import { useEffect, useMemo } from "react";
import { ClassAndTypeFilters } from "react-migration/domains/planning/types";
import { PlanningApplicationLayerFilterProps } from "react-migration/domains/planning/map/layers/PlanningApplicationLayer/planningFilters";
import { TrackingAppender, useEventTracking } from "../../Bundle/EventTrackingProvider";

function bucketMaxYear(maxYear: number) {
  return Math.ceil(maxYear / 5) * 5;
}

export function PlanningApplicationsMapLayerConnected(props: LayerTypeMapLayerProps) {
  const layerId = props.layer.id;
  const { filters, planningApplicationLayerFilters, state } = usePlanningApplicationsContext();

  const { registerTrackingAppender } = useEventTracking();

  useEffect(() => {
    const appender: TrackingAppender = (current) => {
      const classifications = Object.entries({
        Residential: state.showResidential,
        Commercial: state.showCommercial,
        Other: state.showOther,
      })
        .filter(([, v]) => v)
        .map(([k]) => k);

      const types = Object.entries(filters.planningTypes)
        .filter(([, v]) => v)
        .map(([k]) => k);

      return {
        ...current,
        planningApplicationTypesVisible: types,
        planningApplicationClassificationsVisible: classifications,
        planningApplicationMaxYear: state.maxYear,
        planningApplicationMinSize: state.minSize,
        planningApplicationStatusVisible: state.enabledStatuses,
        planningApplicationBoundaryVisible: state.showBoundaries,
      };
    };

    registerTrackingAppender?.(layerId, appender);
  }, [
    filters.planningTypes,
    layerId,
    registerTrackingAppender,
    state.enabledStatuses,
    state.maxYear,
    state.minSize,
    state.showBoundaries,
    state.showCommercial,
    state.showOther,
    state.showResidential,
  ]);

  return (
    <PlanningApplicationsMapLayer
      {...props}
      filters={filters}
      planningApplicationLayerFilters={planningApplicationLayerFilters}
      showBoundaries={state.showBoundaries}
    />
  );
}

type PlanningApplicationsMapLayerProps = Pick<
  LayerTypeMapLayerProps,
  "zOrder" | "visible" | "detailSelection"
> & {
  filters: ClassAndTypeFilters;
  planningApplicationLayerFilters: PlanningApplicationLayerFilterProps;
  showBoundaries: boolean;
};

export function PlanningApplicationsMapLayer({
  zOrder,
  visible,
  detailSelection,
  filters,
  planningApplicationLayerFilters,
  showBoundaries,
}: PlanningApplicationsMapLayerProps) {
  const planningTileUrl = useMemo(() => {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append("maxYear", bucketMaxYear(filters.maxYear || 0).toString());

    return ENVIRONMENT.PLANNING_SERVICE_URL + "/mvt/{z}/{x}/{y}.mvt?" + urlSearchParams;
  }, [filters.maxYear]);

  const focussedApplicationId = detailSelection?.id;

  useMapLayer(
    () => ({
      zOrder,
      layer: new PlanningApplicationLayer({
        ...planningApplicationLayerFilters,
        ...filters,
        binary: true,
        isBoundaryActive: showBoundaries,
        id: "planning-layer",
        data: planningTileUrl,
        visible,
        focussedApplicationId,
      }),
    }),
    [
      zOrder,
      planningApplicationLayerFilters,
      filters,
      showBoundaries,
      planningTileUrl,
      visible,
      focussedApplicationId,
    ],
    visible
  );

  return <></>;
}
