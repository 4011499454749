import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { getStylePropertyValue } from "react-migration/lib/util/getStylePropertyValue";
import { useDebouncedWidth } from "../ScrollableIconNavLinkTabs/useDebouncedWidth";
import { Tag, TagColor, TagProps } from "../Tag";

export interface TagItem extends TagProps {
  id?: string;
}

export interface TagsProps {
  tags: TagItem[];
  placeholder?: string;
  truncate?: boolean;
  availableWidth?: number;
}

export function Tags({ tags, placeholder = "Add label", truncate, availableWidth = 0 }: TagsProps) {
  const parentRef = useRef<HTMLDivElement>(null);
  const remainRef = useRef<HTMLDivElement>(null);
  const childRefs = useRef<HTMLDivElement[]>([]);

  const [truncatedCount, setTruncatedCount] = useState(0);

  const currentWidth = useDebouncedWidth(parentRef);

  useEffect(() => {
    if (!truncate || availableWidth === 0) return;

    const remainingWidth = remainRef.current?.getBoundingClientRect().width || 0;
    const gapSize = parentRef.current
      ? parseFloat(getStylePropertyValue(parentRef.current, "gap"))
      : 0;

    let totalChildWidth = 0;
    let hitLimit = false;

    for (const childRef of childRefs.current) {
      const index = childRefs.current.indexOf(childRef);
      const width = childRef.getBoundingClientRect().width || 0;

      totalChildWidth += width;

      if (!hitLimit && totalChildWidth > availableWidth - remainingWidth) {
        setTruncatedCount(index);
        hitLimit = true;
      }
    }

    if (currentWidth + remainingWidth + gapSize < availableWidth) {
      setTruncatedCount(0);
    } else {
      setTruncatedCount((prevState) => (prevState === 0 ? tags.length - 1 : prevState));
    }
  }, [truncate, availableWidth, currentWidth, tags]);

  const remainingCount = useMemo(
    () => (truncatedCount > 0 ? tags.length - truncatedCount : 0),
    [tags, truncatedCount]
  );

  return (
    <>
      <div
        ref={parentRef}
        className={clsx("atlas-flex atlas-gap-1 atlas-items-center atlas-relative", {
          "atlas-overflow-y-auto atlas-flex-wrap": !truncate,
          "atlas-overflow-hidden": truncate,
        })}
      >
        {tags.length > 0 ? (
          tags
            .map((tag, index) => (
              <Tag
                key={tag.id ?? index}
                ref={(element) => element && (childRefs.current[index] = element)}
                {...tag}
              />
            ))
            .filter(Boolean)
        ) : (
          <span className="atlas-text-blueGrey-900 atlas-text-xs atlas-font-medium">
            {placeholder}
          </span>
        )}

        {remainingCount > 0 && (
          <div
            data-testid="tags-gradient"
            className="atlas-absolute atlas-inset-0 atlas-pointer-events-none atlas-z-10 atlas-bg-gradient-to-r atlas-from-transparent atlas-from-75% atlas-to-white"
          />
        )}
      </div>
      {remainingCount > 0 && (
        <Tag
          ref={remainRef}
          key="remaining"
          tag={`+${remainingCount}`}
          color={TagColor.LIGHT_GRAY}
        />
      )}
    </>
  );
}
