import { Button, RadioGroup, TextInput } from "@landtechnologies/components";
import { TransactionPurchaseType } from "react-migration/domains/ownership/typings/Transaction";
import { PaymentTypes, PurchaseType } from "../types";
import { formatToCurrency } from "react-migration/lib/util/numberFormat";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useAccountQuery } from "react-migration/domains/dashboard/topup/hooks/useAccountQuery";
import { TitleIcon } from "./TitleIcon";
import { setShowTopupModal } from "src/js/stores/navigation/actions";
import { PRICE_PER_TITLE_GBP } from "src/js/util/units_and_constants";

type PurchaseFormType = {
  onCancel: () => void;
  titlesToPurchase: string[];
  setSelectedStep: (v: number) => void;
  transactionPurchaseType: TransactionPurchaseType;
  purchaseType?: PurchaseType;
  paymentType: PaymentTypes;
  setPaymentType: (v: PaymentTypes) => void;
  hmlrUsername: string;
  setHmlrUsername: (v: string) => void;
  hmlrPassword: string;
  setHmlrPassword: (v: string) => void;
  handlePurchase: () => void;
};

export const PurchaseTitleModalPurchaseForm = ({
  onCancel,
  titlesToPurchase,
  setSelectedStep,
  transactionPurchaseType,
  purchaseType,
  paymentType,
  setPaymentType,
  hmlrUsername,
  setHmlrUsername,
  hmlrPassword,
  setHmlrPassword,
  handlePurchase,
}: PurchaseFormType) => {
  const { t } = useTranslation();
  const { data: accountData } = useAccountQuery();

  const accountBalance = () => {
    if (!accountData) return;
    return accountData.accountWithViews.land_reg_credit_pounds >= 0
      ? `${formatToCurrency({
          amount: accountData.accountWithViews.land_reg_credit_pounds,
          currency: "GBP",
        })}`
      : t("dashboard.overdrawn_by", {
          amount: `${formatToCurrency({
            amount: accountData.accountWithViews.land_reg_credit_pounds,
            currency: "GBP",
          })}`,
        });
  };

  return (
    <div
      data-testid="step-2-purchase"
      className="atlas-flex atlas-flex-col atlas-mx-4 atlas-h-full"
    >
      <div className="atlas-overflow-y-auto atlas-max-h-[400px] atlas-grow">
        <h2 className="atlas-my-6">Order</h2>
        <div className="atlas-flex">
          <div className="atlas-w-24 atlas-h-24 atlas-overflow-hidden atlas-border atlas-border-border-divider atlas-rounded-md atlas-flex-shrink-0">
            <TitleIcon className="atlas-object-cover atlas-object-center atlas-w-full atlas-h-full" />
          </div>
          <div className="atlas-flex atlas-flex-col atlas-flex-1 atlas-ml-4">
            <div>
              <div className="atlas-flex atlas-justify-between atlas-text-base atlas-font-medium atlas-text-content-secondary">
                <h3>
                  {transactionPurchaseType === TransactionPurchaseType.TITLE_PLAN_DOCUMENT
                    ? "Title Plan"
                    : "Title Register"}
                </h3>
                <p className="atlas-ml-4">
                  {formatToCurrency({
                    amount: titlesToPurchase.length * PRICE_PER_TITLE_GBP,
                    currency: "GBP",
                  })}
                </p>
              </div>
            </div>
            <div className="atlas-flex atlas-items-end atlas-justify-between atlas-flex-1 atlas-text-sm">
              <p className="atlas-text-content-tertiary">Qty {titlesToPurchase.length}</p>
            </div>
          </div>
        </div>

        <h3 className="atlas-my-6">Purchase with</h3>

        <RadioGroup className="">
          <label
            data-testid="purchase-credits"
            className="atlas-flex atlas-items-center atlas-mb-1 atlas-w-full"
            onClick={() => setPaymentType(PaymentTypes.Balance)}
          >
            <RadioGroup.Item
              checked={paymentType === PaymentTypes.Balance}
              className="!atlas-w-4 !atlas-h-4 atlas-flex-non"
              value={PaymentTypes.Balance}
            >
              <RadioGroup.Indicator />
            </RadioGroup.Item>
            <div className="atlas-flex atlas-justify-between atlas-text-content-secondary atlas-w-full">
              <div className="atlas-pl-2">LandTech Balance</div>
              <div className="atlas-flex">
                {`Current balance: ${accountBalance()}`}{" "}
                <div
                  className="atlas-text-content-action atlas-ml-2 atlas-cursor-pointer"
                  onClick={() => setShowTopupModal(true)}
                >
                  Topup?
                </div>
              </div>
            </div>
          </label>
          <label
            data-testid="purchase-hmlr"
            className="atlas-flex atlas-items-center atlas-mb-2 atlas-mt-1"
            onClick={() => setPaymentType(PaymentTypes.HMLR)}
          >
            <RadioGroup.Item
              checked={paymentType === PaymentTypes.HMLR}
              className="!atlas-w-4 !atlas-h-4 atlas-flex-none atlas-text-xs atlas-text-content-secondary"
              value={"greyscale"}
            >
              <RadioGroup.Indicator />
            </RadioGroup.Item>
            <span className="atlas-pl-2">{"HM Land Registry Portal"}</span>
          </label>
        </RadioGroup>

        {paymentType === PaymentTypes.HMLR && (
          <div>
            <div className="atlas-flex atlas-mt-2 atlas-gap-4 atlas-flex-col md:atlas-flex-row">
              <div className="atlas-grow">
                <TextInput
                  label="HM Land Registry Username"
                  value={hmlrUsername}
                  onChange={(e) => setHmlrUsername(e.target.value)}
                />
              </div>
              <div className="atlas-grow">
                <TextInput
                  label="HM Land Registry Password"
                  type="password"
                  value={hmlrPassword}
                  onChange={(e) => setHmlrPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="atlas-pt-2">
              By placing your order, you agree to{" "}
              <a
                href="https://www.gov.uk/government/publications/conditions-of-use-hm-land-registry-business-e-services"
                target="_blank"
                rel="noreferrer"
              >
                HM Land Registry’s Conditions of Use
              </a>
            </div>
          </div>
        )}
      </div>
      <div className="atlas-flex atlas-justify-end atlas-pt-4">
        <Button.Secondary
          onClick={() => onCancel()}
          data-testid="cancel-button"
          className="atlas-mr-2"
        >
          {t("title_purchase.cancel")}
        </Button.Secondary>
        <Button.Secondary
          onClick={() => setSelectedStep(1)}
          data-testid="back-button"
          className="atlas-mr-2"
        >
          {t("title_purchase.back")}
        </Button.Secondary>
        <Button.Primary
          disabled={
            (paymentType === PaymentTypes.HMLR && (!hmlrUsername || !hmlrPassword)) ||
            (paymentType === PaymentTypes.Balance &&
              accountData &&
              titlesToPurchase.length * 3 > accountData.accountWithViews.land_reg_credit_pounds)
          }
          onClick={() => handlePurchase()}
          type="button"
          data-testid="purchase-button"
        >
          {purchaseType === PurchaseType.Repurchase
            ? t("title_purchase.repurchase")
            : t("title_purchase.purchase")}
        </Button.Primary>
      </div>
    </div>
  );
};
