import { twAtlasMerge } from "@landtechnologies/components";
import { MouseEventHandler, ReactNode, forwardRef } from "react";

export enum TagColor {
  GREEN = "GREEN",
  GRAY = "GRAY",
  LIGHT_GRAY = "LIGHT_GRAY",
  RED = "RED",
  ORANGE = "ORANGE",
  DEFAULT = "DEFAULT",
}

export interface TagProps {
  tag: ReactNode;
  color?: TagColor;
  title?: string;
  onClose?: MouseEventHandler<HTMLDivElement>;
  className?: string;
}

const Tag = forwardRef<HTMLDivElement, TagProps>(
  ({ tag, title, color, className, onClose }, tagRef) => (
    <div
      ref={tagRef}
      title={title}
      className={twAtlasMerge(
        "atlas-text-xs",
        "atlas-font-medium",
        "atlas-leading-4",
        "atlas-rounded",
        "atlas-px-2",
        "atlas-py-0.5",
        "atlas-inline-block",
        {
          "atlas-bg-green-50 atlas-text-green-400": color === TagColor.GREEN,
          "atlas-bg-neutral-600 atlas-text-content-inverse-primary": color === TagColor.GRAY,
          "atlas-bg-blueGrey-200 atlas-text-blueGrey-950/50": color === TagColor.LIGHT_GRAY,
          "atlas-bg-red-50 atlas-text-red-500": color === TagColor.RED,
          "atlas-bg-orange-50 atlas-text-orange-500": color === TagColor.ORANGE,
          "atlas-bg-platformBlue-200 atlas-text-platformBlue-700": color === TagColor.DEFAULT,
          "atlas-bg-blue-50 atlas-text-neutral-600": !color,
        },
        className
      )}
    >
      <div className="atlas-flex atlas-text-nowrap">
        {tag}
        {onClose && (
          <div onClick={onClose} className="atlas-cursor-pointer" data-testid="remove-label">
            <i className="icon-lt-close-line atlas-ml-1" />
          </div>
        )}
      </div>
    </div>
  )
);

Tag.displayName = "Tag";

export { Tag };
