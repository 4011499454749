import { useMemo } from "react";
import { SelectionFeature } from "src/js/stores/map/store";
import { buildConstraintsTileUrl } from "react-migration/domains/constraints/components/ConstraintLayer/ConstraintLayer";
import { StaticMapWithSelection } from "react-migration/domains/sites/card/Map/renderers/StaticMapReact";
import { DesignationLayer } from "react-migration/domains/constraints/components/ConstraintLayer/DesignationLayer";
import { useConstraintsLayerTypeContext } from "../ConstraintsContext";
import { tileDesignationIsVisible } from "../ConstraintsContext/utils";
import { ConstraintLayerConfig } from "../types";

interface PrintableMapLayerProps {
  id: string;
  selection: SelectionFeature;
  layerConfig: ConstraintLayerConfig;
  onLoaded: () => void;
}

export function PrintableMapLayer({
  layerConfig,
  id,
  selection,
  onLoaded,
}: PrintableMapLayerProps) {
  const { constraintsStore } = useConstraintsLayerTypeContext();
  const layerState = constraintsStore.layers[id];

  const mapLayers = useMemo(() => {
    if (!layerState.visibleCategories.length) return;

    return [
      new DesignationLayer({
        id: id,
        data: buildConstraintsTileUrl(layerConfig),
        featureIsVisible: (designation) => tileDesignationIsVisible(designation, layerState),
      }),
    ];
  }, [id, layerConfig, layerState]);

  return (
    <StaticMapWithSelection id={id} layers={mapLayers} onReady={onLoaded} selection={selection} />
  );
}
