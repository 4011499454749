import area from "@turf/area";
import { SelectionFeature } from "src/js/stores/map/store";

export function useMaxAreaSelectedElevationConstraint(selection: SelectionFeature) {
  const MAX_AREA_CONSTRAINT_HECTARES = 100;
  const MAX_AREA_CONSTRAINT_METERS = MAX_AREA_CONSTRAINT_HECTARES * 10000;
  const selectionGeometry = selection.feature?.geometry;
  const selectionFeatureArea = selectionGeometry ? area(selectionGeometry) : 0;

  const exceedsMaximumAreaConstraint = selectionFeatureArea > MAX_AREA_CONSTRAINT_METERS;

  return { exceedsMaximumAreaConstraint };
}
