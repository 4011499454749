import { useEffect, useMemo, useRef, useState } from "react";
import { LabelItem } from "@landtechnologies/components";
import { getStylePropertyValue } from "react-migration/lib/util/getStylePropertyValue";
import { useDebouncedWidth } from "../ScrollableIconNavLinkTabs/useDebouncedWidth";
import { TagItem, Tags } from "../Tags";
import { TagColor } from "../Tag";
import { AddTagButton, PopoverSide } from "./AddTagButton";

export interface TagsSelectionProps {
  selectedLabels: LabelItem[];
  allLabels: LabelItem[];
  noLabelsText: string;
  labelExistsText: string;
  searchInputPlaceHolder: string;
  onRemoveLabel: (id: string) => void;
  onAddLabel: (item: LabelItem) => void;
  onArchiveLabel: (item: LabelItem) => void;
  onUpdateLabel: ({ id, name }: { id: string; name: string }) => void;
  onCreateLabel: (name: string) => void;
  truncate?: boolean;
  popoverSide?: PopoverSide;
}

export function TagsSelect({
  selectedLabels,
  allLabels,
  noLabelsText,
  labelExistsText,
  searchInputPlaceHolder,
  onRemoveLabel,
  onAddLabel,
  onArchiveLabel,
  onUpdateLabel,
  onCreateLabel,
  truncate,
  popoverSide = "bottom",
}: TagsSelectionProps) {
  const parentRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const currentWidth = useDebouncedWidth(parentRef);

  const [availableWidth, setAvailableWidth] = useState(0);

  useEffect(() => {
    if (!truncate) {
      return;
    }

    const width = currentWidth || parentRef.current?.offsetWidth || 0;
    const buttonWidth = buttonRef.current?.offsetWidth ?? 0;
    const gapSize = parentRef.current
      ? parseFloat(getStylePropertyValue(parentRef.current, "gap"))
      : 0;
    const calculatedWidth = width - gapSize - buttonWidth;

    setAvailableWidth(calculatedWidth);
  }, [truncate, currentWidth]);

  const suggestedLabels = useMemo(
    () =>
      allLabels.filter(
        (label) => !selectedLabels.find((assignedLabel) => label.id === assignedLabel.id)
      ),
    [allLabels, selectedLabels]
  );

  const tags = useMemo<TagItem[]>(
    () =>
      selectedLabels.map((label) => ({
        id: label.id,
        tag: label.name,
        color: TagColor.DEFAULT,
        onClose: (e) => {
          e.stopPropagation();
          onRemoveLabel(label.id);
        },
      })),
    [onRemoveLabel, selectedLabels]
  );

  return (
    <div
      ref={parentRef}
      className="atlas-flex atlas-items-center atlas-gap-1 atlas-relative atlas-py-0.5"
    >
      <Tags
        tags={tags}
        placeholder={noLabelsText}
        truncate={truncate}
        availableWidth={availableWidth}
      />
      <div ref={buttonRef} className="atlas-flex atlas-items-center">
        <AddTagButton
          title={noLabelsText}
          allLabels={allLabels}
          suggestedLabels={suggestedLabels}
          selectedLabels={selectedLabels}
          onAddLabel={onAddLabel}
          onArchiveLabel={onArchiveLabel}
          onUpdateLabel={onUpdateLabel}
          onCreateLabel={onCreateLabel}
          labelExistsText={labelExistsText}
          searchInputPlaceHolder={searchInputPlaceHolder}
          popoverSide={popoverSide}
        />
      </div>
    </div>
  );
}
