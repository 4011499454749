import { useMemo } from "react";
import { GeoJsonLayer } from "@deck.gl/layers";
import type { Color } from "@deck.gl/core";
import type { RGBColor } from "d3-color";
import { color } from "d3-color";
import { useMapLayer } from "react-migration/lib/map/useMapLayer";

import { SelectionType } from "src/js/stores/map/store";
import { LayerTypeMapLayerProps } from "../../types";
import { UtilityFeature } from "./types";
import { useUtilitiesLayerTypeContext } from "./UtilitiesContext";

const TRANSPARENT: Color = [255, 255, 255, 0];
const SITE_BOUNDARY_CATEGORY = "SiteBoundary";

export const UtilitiesMapLayer = ({
  detailSelection,
  setDetailSelection,
  zOrder,
  visible,
}: LayerTypeMapLayerProps) => {
  const { expandedCategory, expandedProvider, utilitiesFeatures } = useUtilitiesLayerTypeContext();

  const filteredData = useMemo(() => {
    if (!utilitiesFeatures) {
      return [];
    }

    const features = utilitiesFeatures.filter(
      (d) => d.properties.Category !== SITE_BOUNDARY_CATEGORY
    );

    if (detailSelection?.type === SelectionType.UTILITY_REPORT) {
      return features.filter((d) => d.properties.UniqueID === detailSelection.id);
    } else if (expandedCategory) {
      const filteredByCategory = features.filter((d) => d.properties.Category === expandedCategory);

      if (expandedProvider) {
        return filteredByCategory.filter((c) => c.properties.Provider === expandedProvider);
      }
      return filteredByCategory;
    }

    return features;
  }, [utilitiesFeatures, detailSelection, expandedCategory, expandedProvider]);

  useMapLayer(
    () => ({
      zOrder,
      layer: new GeoJsonLayer<UtilityFeature>({
        id: "utility-layer",
        pickable: !detailSelection,
        autoHighlight: !detailSelection,
        highlightColor: [0, 235, 135, 255],
        lineJointRounded: true,
        lineCapRounded: true,
        onClick({ object }, { srcEvent }) {
          // @ts-expect-error incorrect typings
          srcEvent.domEvent?.preventDefault?.();

          setDetailSelection({
            type: SelectionType.UTILITY_REPORT,
            id: object.properties.UniqueID,
          });
        },
        getFillColor: (d): Color => {
          // @ts-expect-error after updating to deck.gl 9.x the typings expect this to be available at d.properties.properties
          const parsedColor = color(d.properties.fill);
          if (!parsedColor) return TRANSPARENT;
          const { r, g, b } = parsedColor as RGBColor;
          // @ts-expect-error after updating to deck.gl 9.x the typings expect this to be available at d.properties.properties
          const a = (d.properties["fill-opacity"] ?? 1) * 255;
          return [r, g, b, a];
        },
        getLineColor: (d): Color => {
          // @ts-expect-error after updating to deck.gl 9.x the typings expect this to be available at d.properties.properties
          const parsedColor = color(d.properties.stroke);
          if (!parsedColor) return [0, 0, 0, 0];
          const { r, g, b } = parsedColor as RGBColor;
          // @ts-expect-error after updating to deck.gl 9.x the typings expect this to be available at d.properties.properties
          const a = (d.properties["stroke-opacity"] ?? 1) * 255;
          return [r, g, b, a];
        },
        getLineWidth: (d) => {
          // @ts-expect-error after updating to deck.gl 9.x the typings expect this to be available at d.properties.properties
          return d.properties["stroke-width"] ?? 2;
        },
        lineWidthUnits: "pixels",
        data: filteredData,
      }),
    }),
    [zOrder, detailSelection, filteredData, setDetailSelection],
    visible
  );

  return null;
};
