import { useEffect, useMemo } from "react";
import { LayerTypePrintableProps } from "../../types";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { usePlanningApplicationsByGeometry } from "./SelectionSidebar/usePlanningApplicationsByGeometry";
import { Table } from "@landtechnologies/components";
import { PlanningApplication } from "react-migration/domains/planning/types";
import orderBy from "lodash/orderBy";
import { max } from "lodash";
import {
  EMPTY_IDS,
  useSiteReportConfigurationState,
} from "react-migration/lib/util/useSiteReportConfiguration";
import { clsx } from "clsx";
import { Checkbox } from "react-migration/components/Checkbox";

/** Planning Applications are identified via their address. */
function planningIdentifier<T extends { address: string }>(planningApplication: T) {
  return planningApplication.address;
}

export function PlanningApplicationPrintable({
  selection,
  onLoaded,
  onUnmount,
}: LayerTypePrintableProps) {
  const { t } = useTranslation();
  const { data: planningApplications, loading } = usePlanningApplicationsByGeometry(
    selection.feature?.geometry ?? null
  );

  useEffect(() => {
    if (!loading) onLoaded();
  }, [loading, onLoaded]);

  useEffect(() => {
    return () => {
      onUnmount();
    };
  }, [onUnmount]);

  const planningIds = useMemo(
    () => planningApplications?.map(planningIdentifier) ?? EMPTY_IDS,
    [planningApplications]
  );

  const { hiddenIds, allHidden, allVisible, toggleId, toggleAll } = useSiteReportConfigurationState(
    "excludePlanningApplicationIds",
    selection.id as string,
    planningIds
  );

  const renderCell = (value?: string) => (
    <span className="print:atlas-whitespace-pre-line print:atlas-w-[250px]">{value}</span>
  );

  return (
    <div
      className={clsx("atlas-mb-10 print:atlas-mt-4", { "print:atlas-hidden": allHidden })}
      data-testid="planning-report"
    >
      <h3 className="atlas-mb-2">{t("sites.card.site_report.planning")}</h3>
      <div className="atlas-mb-4">
        {t("sites.card.site_report.planning.estimated_maximum_dwellings_planned_for_site")}:{" "}
        {max(planningApplications?.map((planningApplication) => planningApplication.num_dwellings))}
      </div>
      <div className="atlas-mb-1 atlas-italic atlas-text-neutral-500">
        {t("sites.card.site_report.planning.planning_applications_by_classification_and_distance")}
      </div>
      <div className="atlas-mb-4">
        <TableSummary planningApplications={planningApplications ?? []} />
      </div>
      {(planningApplications?.length ?? 0) > 0 && (
        <>
          <div
            className={`atlas-mb-1 atlas-italic atlas-text-neutral-500 ${
              planningApplications && planningApplications?.length > 4
                ? "print:atlas-break-before-page"
                : ""
            }`}
          >
            {t("sites.card.site_report.planning.planning_applications_on_the_site")}
          </div>
          <Table
            size="small"
            primaryKey="ref"
            rowClassName={(planningApplication) =>
              clsx({
                "!atlas-bg-statusRed-50 print:atlas-hidden":
                  !!planningApplication &&
                  hiddenIds.includes(planningIdentifier(planningApplication)),
              })
            }
            columns={[
              {
                grow: true,
                label: (
                  <div className="print:atlas-hidden">
                    <Checkbox checked={allVisible} onCheckedChange={toggleAll} />
                  </div>
                ),
                render: (planningApplication) => (
                  <div className="print:atlas-hidden">
                    <Checkbox
                      checked={!hiddenIds.includes(planningIdentifier(planningApplication))}
                      onCheckedChange={() => toggleId(planningIdentifier(planningApplication))}
                    />
                  </div>
                ),
              },
              {
                label: t("sites.card.site_report.planning.reference"),
                rowKey: "ref",
                grow: true,
              },
              {
                label: t("sites.card.site_report.planning.address"),
                render: ({ address }) => renderCell(address),
                grow: true,
              },
              {
                label: t("sites.card.site_report.planning.description"),
                render: ({ title }) => renderCell(title),
                grow: true,
              },
              {
                label: t("sites.card.site_report.planning.type"),
                rowKey: "types_derived",
                grow: true,
              },
              {
                label: t("sites.card.site_report.planning.decision"),
                render: (row) =>
                  renderCell(
                    row.decision + (row.appeal_decision ? ` (Appeal: ${row.appeal_decision})` : "")
                  ),
                grow: true,
              },
              {
                label: t("sites.card.site_report.planning.classification"),
                grow: true,
                render: (row) =>
                  classifications.find((classification) => row.classification === classification.id)
                    ?.display_name,
              },
              {
                label: t("sites.card.site_report.planning.decision_date"),
                render: (row) => new Date(row.decision_date as string).toLocaleDateString(),
                grow: true,
              },
              {
                label: t("sites.card.site_report.planning.status"),
                rowKey: "status_derived",
                grow: true,
              },
            ]}
            rows={orderBy(
              planningApplications.filter(({ ref }) => !hiddenIds.includes(ref)),
              (application) => application.decision_date,
              "desc"
            )}
            tableClassName="atlas-block atlas-overflow-x-scroll print:atlas-table print:atlas-table-fixed print:atlas-w-full"
          />
        </>
      )}
    </div>
  );
}

export const TableSummary = ({
  planningApplications,
}: {
  planningApplications: PlanningApplication[];
  isImperial?: boolean;
}) => {
  const { t } = useTranslation();
  const groupedPlanningApplications = classifications?.map((classification) => {
    const onSite = planningApplications?.filter(
      (planningApplication) => planningApplication.classification === classification.id
    );

    return {
      classification: classification.display_name,
      onSite: onSite.length,
    };
  });

  return (
    <Table
      size="small"
      primaryKey="classification"
      columns={[
        {
          label: t("sites.card.site_report.planning.classification"),
          render: (row) => toTitleCase(row.classification.replace("_", " ")),
        },
        { label: t("sites.card.site_report.planning.within_site"), rowKey: "onSite" },
        // TODO: include within 1-50m column when hook supports it
      ]}
      rows={groupedPlanningApplications}
    />
  );
};

const classifications = [
  { id: "RESIDENTIAL", display_name: "Residential" },
  { id: "COMMERCIAL", display_name: "Commercial" },
  { id: "MIXED_USE", display_name: "Mixed Use" },
  { id: "OTHER", display_name: "Other" },
  { id: "UNKNOWN", display_name: "Unknown" },
];

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};
