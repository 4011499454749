import { GoogleMapsOverlay } from "@deck.gl/google-maps";
import { subscribeKey } from "valtio/utils";
import Feature from "src/js/stores/user/Feature";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";
import { captureException } from "src/js/util/monitoring";
import { DeckLoadingIndicatorAdapterWidget } from "react-migration/domains/map/util/DeckLoadingIndicatorAdapterWidget";
import { DeckGLClickHandler, DeckLayer, mapStore as state } from "../../stores/map/store";
import { trackMetrics, initDeckMetricsTracking, stopDeckMetricsTracking } from "./metrics";
import { hasPerfSetting } from "react-migration/domains/nav/components/Modals/DebugModal/performanceSettingsStore";

export function sortLayersByZOrder({ zOrder: z1 = 1 }: DeckLayer, { zOrder: z2 = 1 }: DeckLayer) {
  return z1 - z2;
}

subscribeKey(state, "deckLayers", (v) => {
  deckInstance?.setProps({
    layers: v.sort(sortLayersByZOrder).map((l) => {
      return l.layer;
    }),
  });
});

type DeckInstance = null | GoogleMapsOverlay;

let deckInstance: DeckInstance;
export const getDeckInstance = () => deckInstance;

/**
 * Create or Get the current deckInstance
 * @param onClickHandler
 * @returns typeof deckOverlay
 */
export function createDeckGLInstance(onClickHandler: DeckGLClickHandler): typeof deckInstance {
  if (!deckInstance) {
    // create the deck layer
    deckInstance = new GoogleMapsOverlay({
      widgets: [new DeckLoadingIndicatorAdapterWidget({ mapStore: state })],
      onClick: onClickHandler,
      ...(!hasPerfSetting("googleMapsVector") && {
        deviceProps: {
          // @ts-expect-error this prop can be passed. In current version of deck.gl 9.0.20 if we pass preserveDrawingBuffer: true, it will throw an error unless type is 'webgl'
          type: "webgl",
          preserveDrawingBuffer: true,
        },
      }),
      ...(hasBetaFeature(Feature.disableUseDevicePixels) && {
        // Render at logical pixels instead of device pixels for improved perf
        // https://deck.gl/docs/api-reference/core/deck#usedevicepixels
        interleaved: false,
        useDevicePixels: false,
      }),
      // Required for exporting map image
      // https://github.com/niklasvh/html2canvas/issues/1311#issuecomment-663706292
      onError: (error: unknown) => {
        console.error(error);
        captureException(error);
        if ((error as Error)?.message?.toLowerCase() === "webgl context is lost") {
          const lastCrashReload = parseInt(localStorage.lastCrashReload || "0", 10);
          if (!isNaN(lastCrashReload) && Date.now() - lastCrashReload < 5000) {
            captureException(new Error("possible crash loop in process"));
            return true;
          }
          window.location.reload();
          return true;
        }
      },
      _onMetrics: trackMetrics,
    });

    initDeckMetricsTracking();
  }

  return deckInstance;
}

/**
 * Initialise the deck map once we have added the layers
 * @param map google.maps.Map
 */
export function initDeckglInstance(map: google.maps.Map): void {
  if (deckInstance) deckInstance.setMap(map);
}

/**
 * Destroy the current deckgl instance if one exists
 */
export function destroyDeckInstance(): void {
  if (deckInstance) {
    deckInstance.finalize();
    deckInstance = null;
    state.deckInitialised = false;
  }

  stopDeckMetricsTracking();
}
