import { LayerType } from "../../types";
import { PlanningApplicationsProvider } from "./Context";
import { PlanningApplicationsControlPage } from "./ControlPage";
import { PlanningApplicationsMapLayerConnected } from "./MapLayer";
import { PlanningApplicationPrintable } from "./PlanningApplicationPrintable";
import { PlanningApplicationRightClickMenu } from "./PlanningApplicationRightClickMenu";
import { PlanningApplicationSelectionSidebar } from "./SelectionSidebar";
import { planningApplicationsClickResolver } from "./planningClickResolver";
import { PLANNING_APPS_LAYER_TYPE_ID } from "./types";

export const PlanningApplicationsLayerType: LayerType = {
  id: PLANNING_APPS_LAYER_TYPE_ID,
  ControlPage: PlanningApplicationsControlPage,
  MapLayer: PlanningApplicationsMapLayerConnected,
  clickResolver: planningApplicationsClickResolver,
  Provider: PlanningApplicationsProvider,
  RightClickMenu: PlanningApplicationRightClickMenu,
  SelectionSidebar: PlanningApplicationSelectionSidebar,
  Printable: PlanningApplicationPrintable,
};
