import { SelectionType } from "src/js/stores/map/store";
import { OwnershipDesignationSelectionHandler } from "../layer_types/ConstraintsLayerType/OwnershipDesignation";
import {
  OwnershipParcelSelectionHandler,
  OwnershipTitleSelectionHandler,
  OwnersSelectionHandler,
} from "../layer_types/OwnershipLayerType";
import { SitesSelectionHandler } from "../layer_types/SitesLayerType";
import { SelectionHandler } from "../types";
import { UtilitySelectionHandler } from "../layer_types/UtilitiesLayerType/UtilitySelectionHandler";
import { PointSelectionHeader } from "./PointSelectionHeader";
import {
  HometrackComparableSelectionHandler,
  LandTechComparableSelectionHandler,
} from "../layer_types/ComparablesLayerType/SelectionHandlers";
import { UKPropertyInformationSelectionHandler } from "../layer_types/UKPropertyInformationLayerType/UKPropertyInformationSelectionHandler/UKPropertyInformationSelectionHandler";
import { DesignationSelectionHandler } from "../layer_types/ConstraintsLayerType";
import { OutputAreaSelectionHandler } from "../layer_types/DemographicsLayerType/DemographicsLayerType";
import { PlanningApplicationSelectionHandler } from "../layer_types/PlanningApplicationsLayerType/PlanningApplicationSelectionHandler";
import { AnnotationsSelectionHandler } from "../layer_types/Drawings";
import { ContourSelectionHandler } from "../layer_types/TopographyLayerType";

export const SELECTION_HANDLERS: Record<SelectionType, SelectionHandler | undefined> = {
  LANDTECH_COMPARABLE_TRANSACTION: LandTechComparableSelectionHandler,
  HOMETRACK_COMPARABLE_TRANSACTION: HometrackComparableSelectionHandler,
  DESIGNATION: DesignationSelectionHandler,
  LAND_ASSEMBLY: undefined, // This is registered in the handler, to avoid a circular-reference in the LandAssemblySelectionView.
  OWNERSHIP_TITLE: OwnershipTitleSelectionHandler,
  OWNERSHIP_PARCEL: OwnershipParcelSelectionHandler,
  OWNERSHIP_DESIGNATION: OwnershipDesignationSelectionHandler,
  OWNER: OwnersSelectionHandler,
  PLANNING_APPLICATION: PlanningApplicationSelectionHandler,
  UK_PROPERTY: UKPropertyInformationSelectionHandler,
  SITE: SitesSelectionHandler,
  OUTPUT_AREA: OutputAreaSelectionHandler,
  POINT: {
    SelectionHeader: PointSelectionHeader,
  },
  UTILITY_REPORT: UtilitySelectionHandler,
  ANNOTATION: AnnotationsSelectionHandler,
  CONTOUR: ContourSelectionHandler,
};
