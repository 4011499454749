import { useMemo } from "react";
import { useSnapshot } from "valtio/react";
import { TextWithVariants } from "@landtechnologies/components";
import { userStore } from "src/js/stores/user/store";
import { getLengthVariants } from "react-migration/lib/util/getAreaAndPerimeter";

interface UserUnitLengthProps {
  lengthMeters?: number;
}
export function UserUnitLength({ lengthMeters }: UserUnitLengthProps) {
  const { user } = useSnapshot(userStore);

  const lengthMetersVariants = useMemo(
    () => getLengthVariants(lengthMeters, user.settings.unit),
    [lengthMeters, user.settings.unit]
  );

  return <TextWithVariants variants={lengthMetersVariants} />;
}
