import { LayerType, SelectionHandler } from "../../types";
import { DesignationSelectionHandler } from "../ConstraintsLayerType";
import { topographyClickResolver } from "./topographyClickResolver";
import { TopographySelectionSidebar } from "./TopographySelectionSidebar";
import { TopographyControlPage } from "./TopographyControlPage";
import { TopographyPrintable } from "./TopographyPrintable";
import { TopographyMapLayer } from "./TopographyMapLayer";

export const TopographyLayerType: LayerType = {
  id: "TopographyLayerType",
  MapLayer: TopographyMapLayer,
  ControlPage: TopographyControlPage,
  clickResolver: topographyClickResolver,
  SelectionSidebar: TopographySelectionSidebar,
  Printable: TopographyPrintable,
};

/*
  - showTabsForSelection used to control tabs for a selection handler, make it
    config on the selection type or the selection itself
  - had to duplicate a large amount of the constraints click resolver to drive
    contour selections as they use the constraints layer
*/

export const ContourSelectionHandler: SelectionHandler = {
  SelectionTitle: DesignationSelectionHandler.SelectionTitle,
  SelectionHeader: DesignationSelectionHandler.SelectionHeader,
  SelectionDetailView: DesignationSelectionHandler.SelectionDetailView,
};
