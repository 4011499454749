import { Dispatch, FC, SetStateAction, useEffect, useRef } from "react";
import type { PickingInfo } from "@deck.gl/core";
import { useSnapshot } from "valtio";
import { DeckGlClickEvent, mapStore } from "src/js/stores/map/store";
import { POINTLESSLY_LARGE_NUMBER } from "src/js/util/units_and_constants";
import { ENVIRONMENT } from "src/js/util/environment";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";
import getPermissions from "src/js/stores/user/actions/getPermissions";
import { userStore } from "src/js/stores/user/store";
import UserFeature from "src/js/stores/user/Feature";
import type { ClickedLatLng } from "react-migration/lib/map/typings";
import { useHasPerfSetting } from "react-migration/domains/nav/components/Modals/DebugModal/performanceSettingsStore";
import { BitmapTileLayer } from "react-migration/lib/map/layers/BitmapTileLayer";
import { useMapLayer } from "react-migration/lib/map/useMapLayer";
import { OwnershipFeature } from "../../typings/Feature";
import { TRANSPARENT } from "../../util/Colors";
import { OwnershipLayer } from "./OwnershipLayer";

const OWNERSHIP_RASTER_TILE_API_URL_TEMPLATE = `${ENVIRONMENT.OWNERSHIP_SERVICE_URL}/raster/tiles/{z}/{x}/{y}`;

export type FeatureFilter = (ownershipFeature: OwnershipFeature) => boolean;
interface OwnershipLayerComponentProps {
  /** RGBA Color for the parcel boundaries */
  boundaryOpacity?: number;
  /**Used to filter ownership features. If not provided, a default function is used which is always true. */
  featureFilter?: FeatureFilter;
  /** Control fill opacity via multiplier. Defaults to 1  */
  fillOpacityMultiplier?: number;
  /** optional id if being rendered multiple times to prevent id name clashes on layers */
  id?: string;
  /** Fill parcels with colors based on proprietorship value */
  displayKnownOwners?: boolean;
  /** Highlight ownership titles on hover default = true */
  highlightOnHover?: boolean;
  /** Width of the parcel boundary lines */
  lineWidth?: number;
  /** Maximum zoom level to display parcels (deck.gl value is 1 lower than google maps) */
  maxZoom?: number;
  /** Minimum zoom level to display parcels (deck.gl value is 1 lower than google maps) */
  minZoom?: number;
  /** Returns event and latlng when parcel is selected  */
  onClickParcel?: (event: DeckGlClickEvent, latLng: ClickedLatLng) => void;
  /** Returns feature and latlng when parcel is hovered  */
  onHoverParcel?: (d: OwnershipFeature, latLng: ClickedLatLng) => void;
  /** Hide parcels above this size (msq) */
  parcelSizeMax?: number;
  /** Hide parcels below this size (msq) */
  parcelSizeMin?: number;
  /** Are parcels intractable when clicked on */
  pickable?: boolean;
  /**Change the view of the sidebar, summary or details or units */
  setSelectedView?: Dispatch<SetStateAction<string>>;
  /** Hide the tile layer */
  visible?: boolean;
  /** Dictates order of layer in deck.gl's top level layer list */
  zOrder?: number;
}

/**
 * Component for displaying the Ownership layer on a map
 */
export const OwnershipLayerComponent: FC<OwnershipLayerComponentProps> = ({
  boundaryOpacity = 1.0,
  displayKnownOwners,
  featureFilter,
  fillOpacityMultiplier = 1,
  highlightOnHover,
  id,
  lineWidth,
  maxZoom,
  minZoom,
  onClickParcel,
  onHoverParcel,
  parcelSizeMin,
  parcelSizeMax,
  pickable,
  setSelectedView,
  visible,
  zOrder,
}) => {
  const { settings, googleMap } = useSnapshot(mapStore);
  const hasRasterOwnership = useHasPerfSetting("rasterOwnership");
  const isUS = useSnapshot(userStore).user?.features?.includes(UserFeature.usAccess);
  const { mapType } = settings;
  const geofenceGeometries = hasBetaFeature(UserFeature.disableGeofence)
    ? undefined
    : getPermissions()?.geofencesGeometries;

  const draggableCursor = useRef("");
  // on unmount ensure we remove any pointer cursor that may have been set by the onHover callback
  useEffect(() => () => googleMap?.setOptions({ draggableCursor: "" }), [googleMap]);
  useMapLayer(
    () => {
      return {
        zOrder,
        layer: hasRasterOwnership
          ? new BitmapTileLayer({
              id: `${id || "ownership-layer"}-raster`,
              data: OWNERSHIP_RASTER_TILE_API_URL_TEMPLATE,
              minZoom,
              maxZoom,
            })
          : new OwnershipLayer({
              id: id || "ownership-layer",
              autoHighlight: highlightOnHover,
              highlightColor: settings.exportingImage ? (TRANSPARENT as number[]) : undefined,
              visible,
              minZoom,
              maxZoom,
              opacity: boundaryOpacity ?? 1.0,
              getLineWidth: lineWidth,
              mapType,
              pickable,
              parcelSizeMin,
              parcelSizeMax: parcelSizeMax === Infinity ? POINTLESSLY_LARGE_NUMBER : parcelSizeMax,
              displayKnownOwners: !isUS && displayKnownOwners,
              geofenceGeometries,
              fillOpacityMultiplier,
              featureFilter,
              onClick: (
                { coordinate, object }: PickingInfo<OwnershipFeature>,
                event: DeckGlClickEvent
              ) => {
                if (coordinate) {
                  if (typeof setSelectedView === "function") {
                    setSelectedView("summary");
                  }
                  const [lng, lat] = coordinate;
                  onClickParcel?.(event, { lat, lng, country: object?.properties?.country });
                }
              },
              onHover: (pickInfo: PickingInfo<OwnershipFeature>) => {
                if (pickInfo.object && pickInfo.coordinate) {
                  const [lng, lat] = pickInfo.coordinate;
                  onHoverParcel?.(pickInfo.object, {
                    lat,
                    lng,
                    country: pickInfo.object?.properties?.country,
                  });
                }
                const nextValue = pickInfo.object?.properties?.title_no ? "pointer" : "";
                if (nextValue !== draggableCursor.current) {
                  googleMap?.setOptions({ draggableCursor: nextValue });
                  draggableCursor.current = nextValue;
                }
              },
            }),
      };
    },
    [
      zOrder,
      hasRasterOwnership,
      id,
      minZoom,
      maxZoom,
      highlightOnHover,
      settings.exportingImage,
      visible,
      boundaryOpacity,
      lineWidth,
      mapType,
      pickable,
      parcelSizeMin,
      parcelSizeMax,
      isUS,
      displayKnownOwners,
      geofenceGeometries,
      fillOpacityMultiplier,
      featureFilter,
      setSelectedView,
      onClickParcel,
      onHoverParcel,
      googleMap,
    ],
    visible
  );

  return null;
};
