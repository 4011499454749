import { useMemo } from "react";

import { SelectionFeature } from "src/js/stores/map/store";
import { PlanningApplicationLayerFilterProps } from "react-migration/domains/planning/map/layers/PlanningApplicationLayer/planningFilters";

import { filterApplicationListByLayerFilters } from "../utils/filterApplicationListByLayerFilters";
import { usePlanningApplicationsContext } from "../Context";

import { usePlanningApplicationsByGeometry } from "./usePlanningApplicationsByGeometry";

interface Options {
  useLayerFilters?: boolean;
  filterSelectionFromList?: boolean;
  layerFilters?: PlanningApplicationLayerFilterProps;
}

export function usePlanningApplicationListBySelection(
  selection: SelectionFeature,
  { useLayerFilters = true, filterSelectionFromList = false, layerFilters }: Options = {}
) {
  const { planningApplicationLayerFilters } = usePlanningApplicationsContext();
  const filters = layerFilters || planningApplicationLayerFilters;
  const {
    data: planningApplications,
    loading,
    error,
  } = usePlanningApplicationsByGeometry(selection?.feature?.geometry ?? null);

  const { filteredApplications, initialApplications } = useMemo(() => {
    if (!planningApplications?.length) {
      return { filteredApplications: [], initialApplications: [] };
    }

    const enrichedApplications = planningApplications.map((application) => ({
      ...application,
      _id: application.id,
    }));

    const initialApplications = filterSelectionFromList
      ? enrichedApplications.filter(filterSelection(selection?.id))
      : enrichedApplications;

    const filteredApplications =
      useLayerFilters && filters
        ? filterApplicationListByLayerFilters(initialApplications, filters)
        : initialApplications;

    return {
      filteredApplications,
      initialApplications,
    };
  }, [planningApplications, useLayerFilters, filters, filterSelectionFromList, selection?.id]);

  return {
    filteredPlanningApplications: filteredApplications,
    initialApplications,
    loading,
    error,
  };
}

function filterSelection(selectionId?: string) {
  return function filterApplication<T extends { id: string }>(application: T) {
    return application.id !== selectionId;
  };
}
