import type { LayerType, GenericLayer, SelectionHandler } from "../../types";
import { landAssemblyClickResolver } from "../../selectionUtils";
import { SitesSelectionSidebar } from "./SitesSelectionSidebar";
import { SiteDetailHeader } from "./SiteDetailHeader";
import { SiteSelectionDetailView } from "./SiteSelectionDetailView";
import { SitesMapLayer } from "./MapLayer";
import { sitesClickResolver } from "./sitesClickResolver";
import { SitesControlPage } from "./ControlPage";
import { siteSelectionResolver } from "./siteSelectionResolver";
import { SitesSelectionReportSummary } from "./SitesSelectionReportSummary";
import { SiteRightClickMenu } from "./SitesRightClickMenu";

export type SitesLayer = GenericLayer<{
  captureInternalPolygonClicks?: boolean;
}>;

export const SitesLayerType: LayerType = {
  id: "sites",
  SelectionSidebar: SitesSelectionSidebar,
  MapLayer: SitesMapLayer,
  clickResolver: sitesClickResolver,
  RightClickMenu: SiteRightClickMenu,
  landAssemblyClickResolver: landAssemblyClickResolver(sitesClickResolver),
  ControlPage: SitesControlPage,
};

export const SitesSelectionHandler: SelectionHandler = {
  SelectionDetailView: SiteSelectionDetailView,
  SelectionHeader: SiteDetailHeader,
  selectionResolver: siteSelectionResolver,
  SelectionPrintableSummary: SitesSelectionReportSummary,
};
