import { Feature, feature, point } from "@turf/helpers";
import { PickingInfo } from "deck.gl";
import { SelectionFeature, SelectionType } from "src/js/stores/map/store";
import { getDeckInstance } from "src/js/plugins/deck.gl/deckInstance";
import { designationGeometryByIdQuery } from "react-migration/domains/constraints/designation/hooks/useDesignationGeometryById";
import { getDesignationPointRadius } from "react-migration/domains/constraints/designation/style/accessors";
import { ClickSelectionResolver } from "react-migration/layouts/map/Multilayer/types";
import { CONTOUR_CATEGORIES } from "react-migration/domains/constraints/designation/style/StyleMap";
import {
  DesignationFeature,
  DesignationLayer,
  DesignationLayerLight,
} from "react-migration/domains/constraints/components/ConstraintLayer/DesignationLayer";
import { getDeckLayerInstanceIds } from "react-migration/lib/map/getLayerInstances";
import { createDesignationSelection } from "react-migration/domains/constraints/designation/util/createDesignationSelection";
import { createMultiLineString } from "react-migration/domains/constraints/designation/util/createMultiLineString";

const CONSTRAINT_LAYER_CLASSES = [DesignationLayer, DesignationLayerLight];

function isDesignationFeature(o: Feature): o is DesignationFeature {
  return !!o?.properties && "designation_id" in o.properties;
}

async function createPointSelection(
  designation: DesignationFeature,
  zoomPointScale?: (zoom: number) => number
): Promise<SelectionFeature> {
  const { data } = await designationGeometryByIdQuery(designation.properties.designation_id);
  const geometry = data.designation.designation_geometries[0]?.geometry;

  if ((geometry.type as any) === "MultiPoint")
    throw new Error("MultiPoint geometries are not supported");

  return {
    type: SelectionType.CONTOUR,
    id: designation.properties.designation_id,
    hideConsiderations: true,
    feature: feature(geometry),
    savable: false,
    __zoomPointScale: zoomPointScale,
    __renderGeometry: point(geometry.coordinates as [number, number], {
      radius: getDesignationPointRadius(designation),
    }),
  };
}

export const topographyClickResolver: ClickSelectionResolver = async (
  info: PickingInfo<unknown>
) => {
  const { x, y } = info;
  const deck = getDeckInstance();
  const layerIds = getDeckLayerInstanceIds(CONSTRAINT_LAYER_CLASSES);
  const picked = deck?.pickMultipleObjects({ x, y, layerIds }) || [];

  for (const { object } of picked) {
    if (
      isDesignationFeature(object) &&
      CONTOUR_CATEGORIES.includes(object.properties.sub_category_id || "")
    ) {
      const zoomPointScale = info.layer?.state?.zoomPointScale as (zoom: number) => number;

      switch (object.geometry.type) {
        case "Point": {
          return createPointSelection(object, zoomPointScale);
        }

        case "LineString":
        case "MultiLineString": {
          const designationSelection = await createDesignationSelection(
            object,
            createMultiLineString
          );

          return {
            ...designationSelection,
            type: SelectionType.CONTOUR,
            hideConsiderations: true,
            savable: false,
          };
        }
      }
    }
  }

  return null;
};
