import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Button } from "react-migration/components/Button";
import { Action, ActionGroup, ActionGroups, Actions } from "./DebugActions";

export const CacheDebugMenu = () => {
  const { t } = useTranslation();

  const clearCache = () => {
    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    }
    window.location.reload();
  };

  const clearLocalStorage = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <ActionGroup name={ActionGroups.Cache}>
      <Action group={ActionGroups.Cache} name={Actions.ClearCache}>
        <Button onClick={() => clearCache()}>
          {t("nav.debug_modal.action_group.cache.action.cache.action")}
        </Button>
      </Action>
      <Action group={ActionGroups.Cache} name={Actions.ClearLocalStorage}>
        <Button onClick={() => clearLocalStorage()}>
          {t("nav.debug_modal.action_group.cache.action.local_storage.action")}
        </Button>
      </Action>
    </ActionGroup>
  );
};
