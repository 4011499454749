import { ref } from "valtio";
import { mapStore } from "../store";
import { clearSelectedMarker } from "./clearSelectedMarker";

export function updateSelectedMarker(position: { lat: number; lng: number }) {
  const map = mapStore.googleMap;
  if (!map) return;

  clearSelectedMarker();

  const streetView = map.getStreetView();
  const marker = new google.maps.Marker({
    position,
    map: streetView,
  });

  mapStore.selectedMarker = ref(marker);
}
