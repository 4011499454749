import { useMemo } from "react";
import { NetworkStatus } from "@apollo/client";
import { keyBy } from "lodash";
import clsx from "clsx";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { SiteStage } from "react-migration/domains/sites/typings/SiteStage";
import { Select } from "react-migration/components/Select";
import { Button } from "react-migration/components/Button";

type StageId = string;

export interface StageSelectProps {
  value?: StageId;
  onChange: (id: StageId) => void;
  stages: SiteStage[];
  loading: boolean;
  networkStatus: NetworkStatus;
  size?: "xs" | "sm";
}

const NO_SELECTION = "NO_SELECTION";

export function StageSelect({
  value = NO_SELECTION,
  onChange,
  stages,
  networkStatus,
  loading,
}: StageSelectProps) {
  const { t } = useTranslation();
  const stagesById = useMemo(() => stages && keyBy(stages, "_id"), [stages]);
  const hasNoStages = networkStatus === NetworkStatus.ready && !stages?.length;

  return (
    <div
      className="atlas-flex atlas-flex-col atlas-gap-y-1 atlas-grow atlas-overflow-hidden"
      data-testid="stage-select"
    >
      <div className="atlas-hidden md:atlas-block">
        <Select
          value={value}
          disabled={loading || hasNoStages}
          onValueChange={onChange}
          data-testid="stage-select-dropdown"
          size={Select.Size.XS}
        >
          <Select.Option value={NO_SELECTION} disabled hidden>
            {hasNoStages
              ? t("sites.save_site.save_site_dropdown.create_stage_prompt")
              : t("sites.save_site.save_site_dropdown.select_stage_prompt")}
          </Select.Option>
          {(stages || []).map(({ _id, name }) => (
            <Select.Option key={_id} value={_id}>
              {`${name} ${
                stagesById[_id]?.hidden ? t("sites.save_site.save_site_dropdown.hidden") : ""
              }`}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="atlas-block md:atlas-hidden atlas-overflow-y-auto atlas-max-h-[50vh] atlas-divide-y atlas-divide-neutral-200">
        {(stages || []).map(({ _id, name }) => (
          <div
            key={_id}
            onClick={() => onChange(_id)}
            className={clsx(
              "atlas-text-sm atlas-px-2 atlas-py-3 atlas-rounded",
              value === _id ? "atlas-bg-platform-200" : ""
            )}
          >
            {name} {stagesById[_id]?.hidden && t("sites.save_site.save_site_dropdown.hidden")}
          </div>
        ))}
      </div>
    </div>
  );
}

interface StageSelectPopoverContentProps {
  selectedStageId?: string;
  stages?: SiteStage[];
  loading: boolean;
  networkStatus: NetworkStatus;
  saveHandler(): void;
  closeHandler(): void;
  setSelectedStageId(id?: string): void;
}

const EMPTY_ARRAY: SiteStage[] = [];

export function StageSelectPopoverContent({
  selectedStageId,
  stages = EMPTY_ARRAY,
  loading,
  networkStatus,
  setSelectedStageId,
  closeHandler,
  saveHandler,
}: StageSelectPopoverContentProps) {
  const { t } = useTranslation();

  return (
    <>
      <StageSelect
        value={selectedStageId}
        onChange={setSelectedStageId}
        stages={stages}
        loading={loading}
        networkStatus={networkStatus}
      />
      <div className="atlas-flex atlas-justify-between">
        <Button onClick={closeHandler} variant="tertiary">
          {t("sidebar.react.saved_search_sidebar.cancel")}
        </Button>
        <Button variant="primary" onClick={saveHandler} data-testid="save-site-inner">
          {t("sidebar.react.saved_search_sidebar.save")}
        </Button>
      </div>
    </>
  );
}
