import { ClickSelectionResolver } from "../../types";
import { DesignationFeature } from "react-migration/domains/constraints/components/ConstraintLayer/DesignationLayer";
import { createMultiPolygon } from "react-migration/domains/constraints/designation/util/createMultiPolygon";
import { createDesignationSelection } from "react-migration/domains/constraints/designation/util/createDesignationSelection";

enum PlanningAuthoritySubCategory {
  "local_planning_authority_outer" = "local_planning_authority_outer",
  "local_planning_authority" = "local_planning_authority",
  "local_planning_authority_former" = "local_planning_authority_former",
  "local_planning_authority_national_park" = "local_planning_authority_national_park",
}

interface PlanningAuthority extends DesignationFeature {
  sub_category_id: PlanningAuthoritySubCategory;
}

const PLANNING_AUTHORITY_SUB_CATEGORIES = Object.values(PlanningAuthoritySubCategory);

function isPlanningAuthority(o: any): o is PlanningAuthority {
  return (
    !!o?.properties &&
    "designation_id" in o.properties &&
    PLANNING_AUTHORITY_SUB_CATEGORIES.includes(o.properties.sub_category_id)
  );
}

export const planningAuthorityClickResolver: ClickSelectionResolver = async (info) => {
  if (!info?.object || !isPlanningAuthority(info.object)) return null;

  if (!isPlanningAuthority(info.object)) return null;

  const result = await createDesignationSelection(info.object, createMultiPolygon);

  return result ? { ...result, hideConsiderations: true, savable: false } : null;
};
