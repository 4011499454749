import { RefObject } from "react";
import { useDebounce } from "@landtechnologies/components";
import { useRefElementSize } from "react-migration/lib/util/useElementSize";

export function useDebouncedWidth(ref: RefObject<HTMLDivElement>) {
  const { width } = useRefElementSize(ref);
  const debouncedWidth = useDebounce(width, 100);

  return debouncedWidth;
}
