import { createContext, useContext } from "react";
import { ClassAndTypeFilters } from "react-migration/domains/planning/types";
import { LayerTypeProviderProps } from "../../../types";
import {
  PlanningState,
  PlanningStateDispatch,
  usePlanningFilterState,
} from "../state/usePlanningFilterState";
import { useClassAndTypeFilters } from "./hooks";
import { PlanningApplicationLayerFilterProps } from "react-migration/domains/planning/map/layers/PlanningApplicationLayer/planningFilters";
import { PLANNING_APPS_LAYER_TYPE_ID, PlanningLayerConfig } from "../types";

interface PlanningApplicationsContextProps {
  state: PlanningState;
  stateDispatch: PlanningStateDispatch;
  filters: ClassAndTypeFilters;
  planningApplicationLayerFilters: PlanningApplicationLayerFilterProps;
}

const PlanningApplicationsContext = createContext({} as PlanningApplicationsContextProps);

export const usePlanningApplicationsContext = () => useContext(PlanningApplicationsContext);

export function PlanningApplicationsProvider({ children, layers }: LayerTypeProviderProps) {
  const layer = layers.find((layer) => layer.layerType.id === PLANNING_APPS_LAYER_TYPE_ID);

  const planningLayerConfig = (layer?.layerConfig ?? {}) as PlanningLayerConfig;

  const [state, stateDispatch] = usePlanningFilterState(
    planningLayerConfig.localStorageKey,
    planningLayerConfig.initialFilterState
  );

  const [filters, planningApplicationLayerFilters] = useClassAndTypeFilters(state);

  return (
    <PlanningApplicationsContext.Provider
      value={{
        state,
        stateDispatch,
        filters,
        planningApplicationLayerFilters,
      }}
    >
      {children}
    </PlanningApplicationsContext.Provider>
  );
}
