import cn from "clsx";
import { PlanningClassification } from "../../types";

interface PlanningApplicationClassificationProps {
  classification: PlanningClassification;
}

export function PlanningApplicationClassification({
  classification,
}: PlanningApplicationClassificationProps) {
  return (
    <li
      className={cn(
        "atlas-flex atlas-items-center atlas-justify-center atlas-h-[18px] atlas-w-[18px] atlas-rounded-full atlas-text-white atlas-text-sm",
        {
          "atlas-bg-planning-residential": classification === PlanningClassification.RESIDENTIAL,
          "atlas-bg-planning-commercial": classification === PlanningClassification.COMMERCIAL,
          "atlas-bg-planning-other": classification === PlanningClassification.OTHER,
          "atlas-bg-gradient-to-b atlas-from-planning-residential atlas-to-planning-commercial":
            classification === PlanningClassification.MIXED_USE,
        }
      )}
    >
      {classification === PlanningClassification.RESIDENTIAL && "R"}
      {classification === PlanningClassification.COMMERCIAL && "C"}
      {classification === PlanningClassification.OTHER && "O"}
      {classification === PlanningClassification.MIXED_USE && "M"}
    </li>
  );
}
