import { Icon } from "@landtechnologies/components";
import { LtIcons } from "@landtechnologies/icons/font/lt-icons";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import React, { PropsWithChildren } from "react";

function Label({ children }: PropsWithChildren) {
  return (
    <DropdownMenu.Label className="atlas-text-blueGrey-950/50 atlas-text-xs atlas-uppercase">
      {children}
    </DropdownMenu.Label>
  );
}

interface ItemProps extends DropdownMenu.MenuItemProps {
  leadIcon?: `${LtIcons}`;
  leadComponent?: React.ReactNode;
  tailComponent?: React.ReactNode;
  tailAction?: React.ReactNode;
}

function Item({
  children,
  leadIcon,
  leadComponent,
  tailComponent,
  tailAction,
  ...props
}: ItemProps) {
  return (
    <div className="atlas-flex atlas-gap-2 atlas-items-center atlas-justify-between">
      {[!!leadComponent, !!leadIcon, !!children].some(Boolean) && (
        <DropdownMenu.Item
          {...props}
          className="atlas-grow atlas-flex atlas-items-center atlas-justify-between atlas-gap-2 atlas-p-1 atlas-outline-none atlas-rounded-sm atlas-cursor-pointer atlas-min-w-0 hover:atlas-bg-platformBlue-50"
        >
          <div className="atlas-flex atlas-gap-2 atlas-items-center atlas-min-w-0 atlas-w-full">
            {leadComponent && <div className="atlas-flex-shrink-0">{leadComponent}</div>}
            {leadIcon && (
              <div>
                <Icon icon={`icon-${leadIcon}`} size="md" />
              </div>
            )}
            <div className="atlas-text-blueGrey-950 atlas-flex-grow atlas-text-sm atlas-truncate">
              {children}
            </div>
          </div>
          {tailComponent}
        </DropdownMenu.Item>
      )}
      {tailAction}
    </div>
  );
}

interface SubTriggerProps extends DropdownMenu.DropdownMenuSubTriggerProps {
  tailIcon?: `${LtIcons}`;
}

function SubTrigger({ children, tailIcon = "lt-arrow-right-s-line", ...props }: SubTriggerProps) {
  return (
    <DropdownMenu.SubTrigger
      className="atlas-flex atlas-justify-between atlas-rounded-sm atlas-p-1 atlas-outline-none hover:atlas-bg-platformBlue-50"
      {...props}
    >
      <div className="atlas-text-sm">{children}</div>
      <div className="atlas-text-blueGrey-300">
        <Icon icon={`icon-${tailIcon}`} size="sm" />
      </div>
    </DropdownMenu.SubTrigger>
  );
}

function Content(props: DropdownMenu.MenuContentProps) {
  return (
    <DropdownMenu.Content
      className="atlas-bg-white atlas-rounded-md atlas-p-2 atlas-drop-shadow-lg atlas-min-w-[260px] atlas-max-w-[260px] atlas-border-blueGrey-100"
      {...props}
    />
  );
}

function SubContent(props: DropdownMenu.MenuSubContentProps) {
  return (
    <DropdownMenu.SubContent
      className="atlas-bg-white atlas-max-h-[350px] atlas-overflow-y-scroll atlas-rounded-md atlas-p-2 atlas-drop-shadow-lg atlas-min-w-[260px] atlas-max-w-[260px] atlas-border-blueGrey-100"
      {...props}
    />
  );
}

interface GroupProps extends PropsWithChildren {
  title: string;
}

function Group({ children, title }: GroupProps) {
  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-2">
      <RightClick.Label>{title}</RightClick.Label>
      <div>{children}</div>
    </div>
  );
}

export const RightClick = {
  ...DropdownMenu,
  Group,
  Item,
  Label,
  Content,
  SubTrigger,
  SubContent,
};
