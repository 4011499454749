import { useEffect, useState } from "react";
import { without } from "lodash";
import { LayerTypePrintableProps } from "../../../types";
import { TopographySelectionSidebarInner } from "../TopographySelectionSidebar";
import { TopographyMapLayerPrintable } from "./TopographyMapLayerPrintable";
import { useMaxAreaSelectedElevationConstraint } from "../useMaxAreaSelectedElevationConstraint";

enum Sections {
  Elevation = "Elevation",
  HillshadeContour = "HillshadeContour",
}

export function TopographyPrintable({ selection, onLoaded }: LayerTypePrintableProps) {
  const [stillToLoad, setStillToLoad] = useState<Sections[]>(Object.values(Sections));
  const { exceedsMaximumAreaConstraint } = useMaxAreaSelectedElevationConstraint(selection);

  useEffect(() => {
    if (!stillToLoad.length) {
      onLoaded();
    }
  }, [stillToLoad, onLoaded]);

  return (
    <section className="atlas-flex atlas-flex-col atlas-gap-y-2 atlas-pointer-events-none">
      {!exceedsMaximumAreaConstraint && (
        <>
          <h3>Elevation</h3>
          <TopographySelectionSidebarInner
            selection={selection}
            onLoaded={() => setStillToLoad(without(stillToLoad, Sections.Elevation))}
          />
        </>
      )}

      <h3>Hillshade &amp; Contour</h3>
      <TopographyMapLayerPrintable
        selection={selection}
        onLoaded={() => setStillToLoad(without(stillToLoad, Sections.HillshadeContour))}
      />
    </section>
  );
}
