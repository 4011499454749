import * as RadixPopover from "@radix-ui/react-popover";
import { PropsWithChildren } from "react";
import { twAtlasMerge } from "@landtechnologies/components";
import { Tippy } from "./Tippy";

type Variant = "ERROR";

interface PopoverContentProps extends RadixPopover.PopoverContentProps {
  variant: Variant;
}

function PopoverContent({
  variant,
  children,
  className,
  side,
  align,
  sideOffset = 0,
  ...props
}: PopoverContentProps) {
  return (
    <RadixPopover.Content
      {...props}
      align={align}
      side={side}
      sideOffset={sideOffset}
      className={twAtlasMerge(
        "atlas-z-[99999] atlas-flex atlas-flex-col atlas-gap-1 atlas-py-3 atlas-px-4 atlas-rounded-lg atlas-border atlas-max-w-[calc(200px+32px)] atlas-drop-shadow-xl",
        { "atlas-bg-statusRed-100 atlas-border-statusRed-200": variant === "ERROR" },
        className
      )}
    >
      {children}
      <Arrow align={align} side={side} variant={variant} />
    </RadixPopover.Content>
  );
}

interface ArrowProps {
  variant: Variant;
  side: RadixPopover.PopoverContentProps["side"];
  align: RadixPopover.PopoverContentProps["align"];
}

function Arrow({ align, side, variant }: ArrowProps) {
  return (
    <ErrorPopover.Arrow asChild>
      <div
        className={twAtlasMerge("-atlas-translate-y-[0px]", {
          "atlas-translate-x-[5px]":
            (align === "start" && (side === "top" || side === "right")) ||
            (align === "end" && (side === "bottom" || side === "left")),
          "-atlas-translate-x-[5px]":
            (align === "start" && (side === "bottom" || side === "left")) ||
            (align === "end" && (side === "top" || side === "right")),
        })}
      >
        <Tippy variant={variant} />
      </div>
    </ErrorPopover.Arrow>
  );
}

function PopoverHeader({ children }: PropsWithChildren) {
  return <h2 className="atlas-text-xs atlas-font-medium atlas-text-blueGrey-950">{children}</h2>;
}

function PopoverBody({ children }: PropsWithChildren) {
  return (
    <p className="atlas-text-xs atlas-font-normal atlas-text-blueGrey-950/50 atlas-m-0">
      {children}
    </p>
  );
}

export const ErrorPopover = {
  ...RadixPopover,
  Content: PopoverContent,
  Header: PopoverHeader,
  Body: PopoverBody,
};
