import { hasPerfSetting } from "react-migration/domains/nav/components/Modals/DebugModal/performanceSettingsStore";
import { Nullable } from "src/js/types/Nullable";

export interface DeckMetrics {
  fps: number;
  setPropsTime: number;
  updateAttributesTime: number;
  framesRedrawn: number;
  pickTime: number;
  pickCount: number;
  gpuTime: number;
  gpuTimePerFrame: number;
  cpuTime: number;
  cpuTimePerFrame: number;
  bufferMemory: number;
  textureMemory: number;
  renderbufferMemory: number;
  gpuMemory: number;
}

type DeckMetricsToLog = DeckMetrics & { minFps: number; maxFps: number };

const FIRST_METRICS_LOG_DELAY_MS = 10 * 1000; // log first metrics after 10 seconds

const DEFAULT_AVERAGING_WINDOW_SECONDS = 60 * 10; // 10 minutes
const DEFAULT_METRICS_LOG_FREQUENCY_MS = DEFAULT_AVERAGING_WINDOW_SECONDS * 1000;

const DIAGNOSTIC_AVERAGING_WINDOW_SECONDS = 10; // Average over 10 seconds period
const DIAGNOSTIC_METRICS_LOG_FREQUENCY_MS = DIAGNOSTIC_AVERAGING_WINDOW_SECONDS * 1000;

const metricsWindow: DeckMetrics[] = [];
let timeout: Nullable<ReturnType<typeof setTimeout>>;

// This is not called when the user is not actively viewing the DeckGL instance
// E.g. if the user is on another tab then metrics will not be reported until they return.
export function trackMetrics(metricsDatum: DeckMetrics) {
  const isDiagnosticLoggingEnabled = hasPerfSetting("enableDiagnosticDeckGLLogging");

  const AVERAGING_WINDOW_SECONDS = isDiagnosticLoggingEnabled
    ? DIAGNOSTIC_AVERAGING_WINDOW_SECONDS
    : DEFAULT_AVERAGING_WINDOW_SECONDS;

  if (metricsWindow.length >= AVERAGING_WINDOW_SECONDS) {
    metricsWindow.shift();
  }

  // deck metrics uses getters, need to clone to avoid reference issues
  metricsWindow.push(structuredClone(metricsDatum));
}

export function initDeckMetricsTracking() {
  if (!timeout) {
    metricsWindow.length = 0;
    timeout = setTimeout(logMetrics, FIRST_METRICS_LOG_DELAY_MS);
  }
}

export function stopDeckMetricsTracking() {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
    metricsWindow.length = 0;
  }
}

function logMetrics() {
  import("@datadog/browser-rum")
    .then(({ datadogRum }) => {
      const metrics = getAverageMetrics();

      const isDiagnosticLoggingEnabled = hasPerfSetting("enableDiagnosticDeckGLLogging");

      const METRICS_LOG_FREQUENCY_MS = isDiagnosticLoggingEnabled
        ? DIAGNOSTIC_METRICS_LOG_FREQUENCY_MS
        : DEFAULT_METRICS_LOG_FREQUENCY_MS;

      if (metrics) {
        datadogRum.addAction("performance.deckgl", metrics);
      }

      timeout = setTimeout(logMetrics, METRICS_LOG_FREQUENCY_MS);
    })
    .catch((error) => {
      console.error("Failed to log deck.gl metrics", error);
    });
}

function getAverageMetrics(): Nullable<DeckMetricsToLog> {
  if (!metricsWindow.length) return null;
  const noneZeroMetricsWindow = metricsWindow.filter((metric) => metric.fps > 1);
  if (!noneZeroMetricsWindow.length) return null;

  // The initial value of minFps is often below 1 for it's initial value e.g. '0' or '0.0000034'
  let minFps = metricsWindow[0].fps;
  let maxFps = metricsWindow[0].fps;

  const accumulated: DeckMetrics = noneZeroMetricsWindow.reduce((acc, curr) => {
    //  Ignore the minFps when it's below one to prevent it reporting
    if (minFps < 1) {
      minFps = curr.fps;
    } else {
      minFps = Math.min(minFps, curr.fps);
    }

    maxFps = Math.max(maxFps, curr.fps);

    return {
      fps: curr.fps + acc.fps,
      setPropsTime: curr.setPropsTime + acc.setPropsTime,
      updateAttributesTime: curr.updateAttributesTime + acc.updateAttributesTime,
      framesRedrawn: curr.framesRedrawn + acc.framesRedrawn,
      pickTime: curr.pickTime + acc.pickTime,
      pickCount: curr.pickCount + acc.pickCount,
      gpuTime: curr.gpuTime + acc.gpuTime,
      gpuTimePerFrame: curr.gpuTimePerFrame + acc.gpuTimePerFrame,
      cpuTime: curr.cpuTime + acc.cpuTime,
      cpuTimePerFrame: curr.cpuTimePerFrame + acc.cpuTimePerFrame,
      bufferMemory: curr.bufferMemory + acc.bufferMemory,
      textureMemory: curr.textureMemory + acc.textureMemory,
      renderbufferMemory: curr.renderbufferMemory + acc.renderbufferMemory,
      gpuMemory: curr.gpuMemory + acc.gpuMemory,
    };
  });

  const averaged = Object.fromEntries(
    Object.entries(accumulated).map(([key, value]: [string, number]) => [
      key,
      value / metricsWindow.length,
    ])
  ) as unknown as DeckMetrics;

  return {
    minFps,
    maxFps,
    ...averaged,
  };
}
