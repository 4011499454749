import { useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { isNumber } from "lodash";
import { Point } from "@turf/helpers";
import distance from "@turf/distance";
import { Notification, SkeletonLoading } from "@landtechnologies/components";
import { UserUnitLength } from "react-migration/components/UserUnitLength";
import { DataPanel } from "react-migration/components/DataPanel";
import { LayerTypeSelectionSidebarProps } from "../../types";
import { CollapsibleConsiderationsCard } from "../../Bundle/Workbench/CollapsibleConsiderationsCard";
import { useTopographyStatsQuery } from "./useTopographyStatsQuery";
import { useMaxAreaSelectedElevationConstraint } from "./useMaxAreaSelectedElevationConstraint";

type TopographySelectionSidebarProps = Pick<LayerTypeSelectionSidebarProps, "selection">;

export function TopographySelectionSidebar(props: TopographySelectionSidebarProps) {
  const { exceedsMaximumAreaConstraint } = useMaxAreaSelectedElevationConstraint(props.selection);

  return (
    <CollapsibleConsiderationsCard heading="Topography" name="Topography">
      <div className="atlas-p-2">
        <ErrorBoundary
          fallback={
            <Notification.Error
              title="An error occurred"
              message="We were unable to fetch the topography data for this selection. Please try again."
            />
          }
        >
          {exceedsMaximumAreaConstraint && (
            <Notification.Warning
              title="Selection too large"
              message="Your selection is too large. In order to view topography, please select a smaller area"
            />
          )}
          {!exceedsMaximumAreaConstraint && <TopographySelectionSidebarInner {...props} />}
        </ErrorBoundary>
      </div>
    </CollapsibleConsiderationsCard>
  );
}

type TopographySelectionSidebarInnerProps = TopographySelectionSidebarProps & {
  onLoaded?: () => void;
};

export function TopographySelectionSidebarInner({
  selection,
  onLoaded,
}: TopographySelectionSidebarInnerProps) {
  const { data, loading, error } = useTopographyStatsQuery(selection.feature?.geometry, {
    onLoaded,
  });

  const { maxMeters, minMeters, maxPoint, minPoint } = data || {};
  const elevationChangePercentage = useElevationChangePercentage(
    minMeters,
    maxMeters,
    minPoint,
    maxPoint
  );

  if (error) {
    // Caught by boundary above
    throw error;
  }

  if (loading) {
    return (
      <div className="atlas-relative atlas-h-[46px]">
        <SkeletonLoading rows={1} showPadding={false} />
      </div>
    );
  }

  return (
    <DataPanel.Grid columns={3}>
      <DataPanel.Cell title="Highest">
        <UserUnitLength lengthMeters={maxMeters} />
      </DataPanel.Cell>
      <DataPanel.Cell title="Lowest">
        <UserUnitLength lengthMeters={minMeters} />
      </DataPanel.Cell>
      <DataPanel.Cell title="% Total Change">
        {isNumber(elevationChangePercentage) ? `${elevationChangePercentage}%` : "-"}
      </DataPanel.Cell>
    </DataPanel.Grid>
  );
}

function useElevationChangePercentage(
  minMeters?: number,
  maxMeters?: number,
  minPoint?: Point,
  maxPoint?: Point
) {
  return useMemo(() => {
    if (!isNumber(minMeters) || !isNumber(maxMeters) || !minPoint || !maxPoint) return null;

    const elevationDelta = maxMeters - minMeters;
    const distanceDelta = distance(minPoint, maxPoint, { units: "meters" });

    if (!distanceDelta) return 0;

    return +((elevationDelta / distanceDelta) * 100).toFixed(2);
  }, [maxMeters, maxPoint, minMeters, minPoint]);
}
