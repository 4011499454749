interface ResolutionBadgeProps {
  resolution: string;
}

export function ResolutionBadge({ resolution }: ResolutionBadgeProps) {
  return (
    <div className="atlas-text-xs atlas-h-5 atlas-leading-5 atlas-px-1 atlas-bg-white atlas-text-[rgba(15,19,36,0.6)] atlas-border atlas-border-[rgba(10,15,41,0.08)] atlas-rounded">
      {resolution}
    </div>
  );
}
