import { useCallback } from "react";

import { DebouncedState } from "react-migration/lib/util/useDebounceCallback";
import { TrackControlChange } from "../../../ControlTrackingContext/ControlTrackingContext";
import { PlanningState, PlanningStateDispatch } from "../state/usePlanningFilterState";

import { FilterToggle } from "./FilterToggle";

interface ClassificationTogglesProps {
  disabled?: boolean;
  prefix: string;
  state: PlanningState;
  stateDispatch: PlanningStateDispatch;
  debouncedTrackControlChange: DebouncedState<TrackControlChange>;
}

export function ClassificationToggles({
  disabled,
  prefix,
  state,
  stateDispatch,
  debouncedTrackControlChange,
}: ClassificationTogglesProps) {
  const { showResidential, showCommercial, showOther } = state;

  const handleResidentialChange = useCallback(() => {
    debouncedTrackControlChange({ filterName: "showResidential", filterState: !showResidential });
    stateDispatch({ type: "SET_RESIDENTIAL", value: !showResidential });
  }, [showResidential, stateDispatch, debouncedTrackControlChange]);

  const handleCommercialChange = useCallback(() => {
    debouncedTrackControlChange({ filterName: "showCommercial", filterState: !showCommercial });
    stateDispatch({ type: "SET_COMMERCIAL", value: !showCommercial });
  }, [showCommercial, stateDispatch, debouncedTrackControlChange]);

  const handleOtherChange = useCallback(() => {
    debouncedTrackControlChange({ filterName: "showOther", filterState: !showOther });
    stateDispatch({ type: "SET_OTHER", value: !showOther });
  }, [showOther, stateDispatch, debouncedTrackControlChange]);

  return (
    <>
      <FilterToggle
        disabled={disabled}
        name="Residential"
        prefix={prefix}
        color="#ff3665"
        enabled={showResidential}
        setEnabled={handleResidentialChange}
      />
      <FilterToggle
        disabled={disabled}
        name="Commercial"
        prefix={prefix}
        color="#8e36ff"
        enabled={showCommercial}
        setEnabled={handleCommercialChange}
      />
      <FilterToggle
        disabled={disabled}
        name="Other"
        prefix={prefix}
        color="#30b3df"
        enabled={showOther}
        setEnabled={handleOtherChange}
      />
    </>
  );
}
