import { useSnapshot } from "valtio";
import { proxy, subscribe } from "valtio/vanilla";
import { useBetaFeatureFlag } from "react-migration/lib/user/useFeatureFlag";
import Feature from "src/js/stores/user/Feature";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";

const PERF_SETTINGS_STORAGE_KEY = "performanceSettings";

interface PerformanceSettingsStore {
  rasterConstraints: boolean;
  rasterOwnership: boolean;
  removeInvisibleLayers: boolean;
  notLoadSitesDataIfSitesLayerDisabled: boolean;
  googleMapsVector: boolean;
  enableDiagnosticDeckGLLogging: boolean;
}

const performanceSettingsStore = proxy<PerformanceSettingsStore>(
  getPersistedPerformanceSettings({
    rasterConstraints: false,
    rasterOwnership: false,
    removeInvisibleLayers: false,
    notLoadSitesDataIfSitesLayerDisabled: false,
    googleMapsVector: false,
    enableDiagnosticDeckGLLogging: false,
  })
);

subscribe(performanceSettingsStore, () => {
  localStorage.setItem(PERF_SETTINGS_STORAGE_KEY, JSON.stringify(performanceSettingsStore));
});

function getPersistedPerformanceSettings(defaultSettings: PerformanceSettingsStore) {
  try {
    const parsedSettings = JSON.parse(localStorage.getItem(PERF_SETTINGS_STORAGE_KEY)!);

    if (typeof parsedSettings !== "object") throw new Error("Invalid settings");

    // remove any settings which are no longer used, ensure type of settings is correct
    const cleaned = Object.fromEntries(
      Object.entries(parsedSettings)
        .filter(([key]) => key in defaultSettings)
        .filter(
          ([key, value]) =>
            typeof value === typeof defaultSettings[key as keyof PerformanceSettingsStore]
        )
    );

    return { ...defaultSettings, ...cleaned };
  } catch (e) {
    return defaultSettings;
  }
}

function setPerfSetting(key: keyof PerformanceSettingsStore, value: boolean) {
  performanceSettingsStore[key] = value;
}

export function usePerformanceStore() {
  return [useSnapshot(performanceSettingsStore), setPerfSetting] as const;
}

export function useHasPerfSetting(key: keyof PerformanceSettingsStore) {
  const settings = useSnapshot(performanceSettingsStore);
  if (!useBetaFeatureFlag(Feature.perfDebugMenu)) return false;
  return settings[key];
}

export function hasPerfSetting(key: keyof PerformanceSettingsStore) {
  if (!hasBetaFeature(Feature.perfDebugMenu)) return false;
  return performanceSettingsStore[key];
}
