import { useMemo } from "react";
import type { Color } from "@deck.gl/core";
import { ENVIRONMENT } from "src/js/util/environment";
import { SOURCE_STATUSES } from "react-migration/domains/constraints/constants";
import {
  ZoomPointScale,
  SimplifyProfile,
  CollisionFilter,
} from "react-migration/layouts/map/Multilayer/layer_types/ConstraintsLayerType";
import { useHasPerfSetting } from "react-migration/domains/nav/components/Modals/DebugModal/performanceSettingsStore";
import { BitmapTileLayer } from "react-migration/lib/map/layers/BitmapTileLayer";
import { useMapLayer } from "react-migration/lib/map/useMapLayer";
import { LtIconKey } from "../../designation/style/icons";
import {
  DesignationFeatureProps,
  DesignationLayer,
  DesignationLayerLight,
  DesignationStyleAccessors,
} from "./DesignationLayer";
import { getCategoryFillColour } from "../../designation/style/accessors";

export interface ConstraintLayerProps {
  showLabels?: boolean;
  rootCategory: string;
  iconKeySet?: LtIconKey[];
  includeSourceStatuses?: boolean;
  mapType?: string;
  /** Minimum zoom level to display constraints (deck.gl value is 1 lower than google maps) */
  minZoom: number;
  maxZoom?: number;
  zOrder?: number;
  layerName?: string;
  layerId: string;
  featureIsVisible?: (featureProperties: DesignationFeatureProps) => boolean;
  featureIsSelected?: (featureProperties: DesignationFeatureProps) => boolean;
  designationAttributes?: string[];
  dedupePoints?: boolean;
  filterAreaByZoom?: boolean;
  zoomPointScale?: ZoomPointScale;
  simplifyProfile?: SimplifyProfile;
  collisionFilter?: CollisionFilter;
  styleAccessors?: DesignationStyleAccessors;
}

export type ConstraintsTileOptions = Pick<
  ConstraintLayerProps,
  "rootCategory" | "dedupePoints" | "filterAreaByZoom" | "simplifyProfile" | "designationAttributes"
>;

export const buildConstraintsTileUrl = ({
  rootCategory,
  designationAttributes,
  dedupePoints,
  filterAreaByZoom,
  simplifyProfile,
}: ConstraintsTileOptions) => {
  const queryParams = new URLSearchParams();

  if (designationAttributes?.length)
    queryParams.append("attributes", designationAttributes.join(","));

  queryParams.append("sourceStatuses", SOURCE_STATUSES.map((x) => x.id).join(","));

  if (dedupePoints !== undefined) {
    queryParams.append("dedupePoints", String(dedupePoints));
  }

  if (filterAreaByZoom !== undefined) {
    queryParams.append("filterAreaByZoom", String(filterAreaByZoom));
  }

  if (simplifyProfile !== undefined) {
    queryParams.append("simplifyProfile", simplifyProfile);
  }

  let tileUrl = `${ENVIRONMENT.CONSTRAINTS_VEC_TILE_API_URL}`;
  tileUrl = tileUrl.replace("{root_category}", encodeURIComponent(rootCategory));
  tileUrl = `${tileUrl}?${queryParams}`;

  return tileUrl;
};

const buildConstraintsRasterTileUrl = ({
  rootCategory,
  color,
}: Pick<ConstraintsTileOptions, "rootCategory"> & { color: Color }) => {
  const queryParams = new URLSearchParams();

  queryParams.append("color", JSON.stringify(color));

  let tileUrl = `${ENVIRONMENT.CONSTRAINTS_VEC_TILE_API_URL}`;
  tileUrl = tileUrl.replace("tiles", "tiles/raster");
  tileUrl = tileUrl.replace("mvt", "png");
  tileUrl = tileUrl.replace("{root_category}", encodeURIComponent(rootCategory));
  tileUrl = `${tileUrl}?${queryParams}`;

  return tileUrl;
};

const RASTER_CATEGORIES = [
  "flood_zones_outer",
  "very_low_risk_of_flooding_from_rivers_and_seas",
  "low_risk_of_flooding_from_rivers_and_seas",
  "medium_risk_of_flooding_from_rivers_and_seas",
  "high_risk_of_flooding_from_rivers_and_seas",
  "rights_of_way",
  "national_landscape",
  "conservation_areas_outer",
  "employment_area_outer",
  "settlement_boundaries_outer",
  "green_belt_outer",
  "nutrient_neutrality",
];

const FEATURE_IS_VISIBLE_DEFAULT = () => true;

export function ConstraintLayer({
  rootCategory,
  showLabels = false,
  iconKeySet: iconKeys,
  minZoom,
  maxZoom,
  zOrder,
  layerName,
  layerId,
  featureIsVisible = FEATURE_IS_VISIBLE_DEFAULT,
  featureIsSelected,
  zoomPointScale,
  designationAttributes,
  dedupePoints,
  filterAreaByZoom,
  simplifyProfile,
  collisionFilter,
  styleAccessors,
}: ConstraintLayerProps) {
  const hasRasterConstraintsBeta = useHasPerfSetting("rasterConstraints");

  const mvtUrl = useMemo(() => {
    return buildConstraintsTileUrl({
      rootCategory,
      designationAttributes,
      dedupePoints,
      filterAreaByZoom,
      simplifyProfile,
    });
  }, [rootCategory, designationAttributes, dedupePoints, filterAreaByZoom, simplifyProfile]);

  const LayerClass = useMemo(() => {
    if (hasRasterConstraintsBeta && RASTER_CATEGORIES.includes(rootCategory))
      return BitmapTileLayer;
    // The newer DesignationLayerLight does not yet support labels, styling by attribute, or icons (it would seem)
    if (designationAttributes?.length || iconKeys?.length || showLabels) return DesignationLayer;
    return DesignationLayerLight;
  }, [
    designationAttributes?.length,
    iconKeys?.length,
    showLabels,
    rootCategory,
    hasRasterConstraintsBeta,
  ]);

  useMapLayer(
    () => ({
      zOrder,
      layer:
        LayerClass === BitmapTileLayer
          ? new BitmapTileLayer({
              id: `${layerId}-raster`,
              data: buildConstraintsRasterTileUrl({
                rootCategory,
                color: getCategoryFillColour(rootCategory),
              }),
              minZoom,
              maxZoom,
            })
          : new LayerClass({
              id: layerId,
              data: mvtUrl,
              showLabels,
              collisionFilter: collisionFilter,
              visible: true,
              iconKeySet: iconKeys,
              minZoom,
              maxZoom,
              pickable: true,
              featureIsVisible,
              featureIsSelected,
              zoomPointScale,
              styleAccessors,
              loadOptions: {
                mvt: { layers: [layerName] },
              },
            }),
    }),
    [
      zOrder,
      LayerClass,
      layerId,
      rootCategory,
      minZoom,
      maxZoom,
      mvtUrl,
      showLabels,
      collisionFilter,
      iconKeys,
      featureIsVisible,
      featureIsSelected,
      zoomPointScale,
      styleAccessors,
      layerName,
    ]
  );
  return null;
}
