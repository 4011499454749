import { useMemo, useState } from "react";
import { ZodError, SafeParseReturnType } from "zod";
import { ApolloError, useQuery } from "@apollo/client";
import { cleanTypename } from "react-migration/lib/util/cleanTypename";
import { routedClient } from "react-migration/lib/persistence/apollo";
import TOPOGRAPHY_STATS_QUERY from "./topographyStatsByGeometry.gql";
import type {
  QueryTopographyStatsByGeometryArgs,
  TopographyStatsData,
  TopographyStatsStrict,
} from "./types";
import { topohraphyStatsSchema } from "./topohraphyStatsSchema";

interface UseTopographyStatsQueryOptions {
  onLoaded?(): void;
}

export function useTopographyStatsQuery(
  geometry?: QueryTopographyStatsByGeometryArgs["geometry"] | null,
  options?: UseTopographyStatsQueryOptions
) {
  const cleanedGeometry = useMemo(() => geometry && cleanTypename(geometry), [geometry]);
  const [parsed, setParsed] = useState<SafeParseReturnType<unknown, TopographyStatsStrict>>();
  const { data, ...rest } = useQuery<TopographyStatsData, QueryTopographyStatsByGeometryArgs>(
    TOPOGRAPHY_STATS_QUERY,
    {
      variables: {
        geometry: cleanedGeometry!,
      },
      skip: !cleanedGeometry,
      client: routedClient,
      onCompleted(data) {
        setParsed(topohraphyStatsSchema.safeParse(data?.topographyStatsByGeometry));
        options?.onLoaded?.();
      },
    }
  );

  let error: ZodError | ApolloError | undefined = rest.error;

  if (parsed && !parsed.success) {
    error = parsed.error;
  }

  return { data: data?.topographyStatsByGeometry, error, ...rest };
}
