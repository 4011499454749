import { PickingInfo } from "deck.gl";
import { multiPolygon, polygon } from "@turf/helpers";
import { getDeckInstance } from "src/js/plugins/deck.gl/deckInstance";
import { SelectionType } from "src/js/stores/map/store";
import { getDeckLayerInstanceIds } from "react-migration/lib/map/getLayerInstances";
import { ClickSelectionResolver } from "../../../types";
import { SitesGeoJsonLayer } from "../SitesGeoJsonLayer";
import { isSiteBoundary } from "../utils";

export const sitesClickResolver: ClickSelectionResolver = (pickInfo: PickingInfo<unknown>) => {
  const { x, y } = pickInfo;
  const deck = getDeckInstance();
  const layerIds = getDeckLayerInstanceIds([SitesGeoJsonLayer]);

  for (const pick of deck?.pickMultipleObjects({ x, y, layerIds }) || []) {
    const siteSelection = sitePickResolver(pick);

    if (siteSelection) return siteSelection;
  }

  return null;
};

export function sitePickResolver(pick: PickingInfo<unknown>) {
  if (!isSiteBoundary(pick.object)) return;

  const selectionProperties = {
    type: SelectionType.SITE,
    id: pick.object.id,
    addresses: [pick.object.properties.title],
    savable: false,
  };

  switch (pick.object.geometry?.type) {
    case "MultiPolygon":
      return {
        ...selectionProperties,
        feature: multiPolygon(pick.object.geometry?.coordinates),
      };

    case "Polygon":
      return { ...selectionProperties, feature: polygon(pick.object.geometry?.coordinates) };
  }
}
