import { useMapLayer } from "react-migration/lib/map/useMapLayer";

import { LayerTypeMapLayerProps } from "../../types";
import { MVTLayer } from "@deck.gl/geo-layers";
import { ENVIRONMENT } from "src/js/util/environment";
import { userStore } from "src/js/stores/user/store";

type PropertiesType = {
  name?: string;
  rank: number;
  layerName: string;
  class: string;
};

export function DrawingsMapLayer({ visible }: LayerTypeMapLayerProps) {
  useMapLayer(
    () => ({
      id: "DRAWINGS",
      layer: new MVTLayer<PropertiesType>({
        id: "drawings",
        data: `${ENVIRONMENT.DRAWINGS_SERVICE_URL}/drawings/mvt/${userStore.user._account._id}/{z}/{x}/{y}`,
        minZoom: 0,
        maxZoom: 14,
        getFillColor: [0, 0, 0, 0], // RGBA color
        getLineColor: [255, 0, 0], // RGB color
        lineWidthMinPixels: 2,
        lineWidthMaxPixels: 50,
        stroked: true,
      }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
    visible
  );

  return null;
}
