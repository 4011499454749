import { proxy } from "valtio/vanilla";

export type NavigationStore = {
  showTopupModal: boolean;
  showUpgradeModal: boolean;
  showTrialEndingModal: boolean;
  showMultiDeviceBlockModal: boolean;
  showOverdueInvoiceModal: boolean;
  showDebugModal: boolean;
  showImpersonationBanner: boolean;
  showOverdueInvoiceBanner: boolean;
};

const defaultState = {
  showTopupModal: false,
  showUpgradeModal: false,
  showTrialEndingModal: false,
  showOverdueInvoiceModal: false,
  showDebugModal: false,
  showMultiDeviceBlockModal: false,
  showImpersonationBanner: false,
  showOverdueInvoiceBanner: true,
};

export const createNavigationStore = () => defaultState;
export const navigationStore = proxy<NavigationStore>(createNavigationStore());
